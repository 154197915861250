import { Badge, Form, ListGroup, Button, Modal } from "react-bootstrap";
import { ApplyTemplateStep, ProcessorsPerStep, ApplyApprovementStep, Account } from "./applyValues";
import { Fragment, useMemo, useState } from "react";
import classNames from "classnames";
import { toTimeLabel } from "../../app/util";
import ProcessorSelector from "./ProcessorSelector";

function ApplySteps({
  templateSteps,
  processorsPerSteps,
  runningSteps,
  mode,
  terms,
  loginUserId,
  isAdmin = false,
  onChange,
}: {
  templateSteps: ApplyTemplateStep[];
  processorsPerSteps: ProcessorsPerStep[];
  runningSteps?: ApplyApprovementStep[];
  mode: "editing" | "previewing";
  terms: { [key: string]: string };
  loginUserId: number;
  isAdmin?: boolean;
  onChange: (next: ProcessorsPerStep[]) => any;
}) {
  const [state, $state] = useState({
    modalStepIndex: null as number | null,
    checkedAccounts: [] as Account[],
  });

  const closeModal = () => {
    $state({ ...state, modalStepIndex: null, checkedAccounts: [] });
  };

  return (
    <Fragment>
      {mode === "editing" && <div className="--font-s">※初期表示時の候補を太字で表示しています</div>}
      <ListGroup as="ol" numbered>
        {templateSteps.map(({ name, editable, processors: templateProcessors, copy, hasInput }, stepIndex) => {
          // ステップ毎の承認者（候補含む）
          const processorsPerStep = processorsPerSteps[stepIndex];
          if (!processorsPerStep) return null;
          const { errorMessage, processorChoices } = processorsPerStep;
          // 選択済の承認者のアカウントIDのみ抽出
          const selectedIds = processorChoices.filter((_) => _.isSelected).map((_) => _.id);

          // 作成済の申請書のステップの状態
          const runningStep = runningSteps?.[stepIndex];
          const status = runningStep?.status ?? "todo";

          // 編集可能か（編集モードかつ、完了していない）
          const editableStep = mode === "editing" && status !== "done";
          return (
            <ListGroup.Item
              key={`step${stepIndex}`}
              as="li"
              className={classNames({
                "d-flex": true,
                "justify-content-between": true,
                "align-items-start": true,
                border: true,
                "border-success": status === "running",
              })}
              variant={status === "done" ? "dark" : ""}
            >
              <div className="ms-2 me-auto">
                <div>
                  <span className="fw-bold">{name}</span>
                  {mode === "previewing" && status && (
                    <Fragment>
                      <span
                        className={classNames({
                          "Badge--ok": status === "done",
                          "Badge--waiting": status === "todo",
                          "Badge--running": status === "running",
                          "Badge--danger": status === "rejected",
                          "mx-1": true,
                        })}
                      >
                        {terms[`APPLICATION_STEP_STATUS_${status}`]}
                      </span>
                      {runningStep?.processed_at && (
                        <span className="--font-s">（{toTimeLabel(runningStep?.processed_at)}）</span>
                      )}
                      {status === "done" && !selectedIds.length && (
                        <span className="--font-s">（承認者の設定がないためスキップされました）</span>
                      )}
                    </Fragment>
                  )}
                  {hasInput ? (
                    <Badge pill bg="info" className="mx-1">
                      入力項目あり
                    </Badge>
                  ) : null}
                  {copy ? (
                    <Badge pill bg="success" className="mx-1">
                      データ反映
                    </Badge>
                  ) : null}
                  {errorMessage && <span className="--text-annotation mx-1 --font-s">{errorMessage}</span>}
                </div>
                {editableStep && !templateProcessors.length && (
                  <div className="--text-light-color">（選択可能な候補なし）</div>
                )}
                {!editableStep && !selectedIds.length && <div className="--text-light-color">（承認者設定なし）</div>}
                {processorChoices.map(({ id, label, isSelected }) => {
                  if (editableStep) {
                    // テンプレートに設定されている承認者かどうか
                    const isOriginalProcessor = templateProcessors.some((p) => p.id === id);
                    // 編集可能ステップ or 管理者操作で、テンプレートに存在しない=追加された承認者の場合
                    const editableProcessor = editable || (isAdmin && !isOriginalProcessor);
                    return (
                      <Form.Check
                        checked={isSelected}
                        type="checkbox"
                        id={`step${stepIndex}_${id}`}
                        label={label}
                        key={`step${stepIndex}_${id}`}
                        disabled={!editableProcessor}
                        className={classNames({ "--bold": templateProcessors.some((p) => p.id === id) })}
                        onChange={() => {
                          const next = processorsPerSteps.map((_, i) => {
                            if (i !== stepIndex) return _;
                            const _nextProcessorChoices = _.processorChoices.map((choice) => {
                              return choice.id === id ? { ...choice, isSelected: !isSelected } : choice;
                            });
                            return { ..._, processorChoices: _nextProcessorChoices };
                          });
                          onChange(next);
                        }}
                      />
                    );
                  } else {
                    if (!isSelected) return null;
                    return (
                      <div
                        key={`processor${id}`}
                        className={classNames({ "mx-1": true, "--text-annotation": id === loginUserId })}
                      >
                        {label}
                        {id === runningStep?.processed_by ? "（処理者）" : null}
                      </div>
                    );
                  }
                })}
                {editableStep &&
                  (editable || isAdmin ? (
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        $state({ ...state, modalStepIndex: stepIndex });
                      }}
                    >
                      承認者を追加する
                    </Button>
                  ) : (
                    <span className="--font-s">※承認者の追加不可</span>
                  ))}
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      {state.modalStepIndex !== null && (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={true} onHide={closeModal}>
          <Modal.Body>
            <ProcessorSelector
              selectedAccountIds={processorsPerSteps[state.modalStepIndex].processorChoices
                .filter(({ isSelected }) => isSelected)
                .map(({ id }) => id)}
              checkedAccounts={state.checkedAccounts}
              onChange={(checkedAccounts: Account[]) => {
                $state({ ...state, checkedAccounts });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal} variant="outline-secondary">
              キャンセル
            </Button>
            <Button
              variant="primary"
              disabled={!state.checkedAccounts.length}
              onClick={() => {
                const next = processorsPerSteps.map((_, i) => {
                  if (i !== state.modalStepIndex) return _;
                  // 既存の選択肢に存在する場合はisSelectedをtrueにする
                  const choices = _.processorChoices.map((choice) => {
                    if (!state.checkedAccounts.some((checked) => checked.id === choice.id)) return choice;
                    return { ...choice, isSelected: true };
                  });
                  // 既存に存在しない場合は選択肢追加
                  const additionalChoices = state.checkedAccounts
                    .filter((a) => !choices.some((c) => c.id === a.id))
                    .map(({ id, label }) => ({ id, label, isSelected: true }));
                  return { ..._, processorChoices: [...choices, ...additionalChoices] };
                });
                onChange(next);
                closeModal();
              }}
            >
              決定
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
}

export default ApplySteps;
