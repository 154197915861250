import { ReportTemplate } from "./reportValues";

export const adminReportTemplates = [
  {
    id: "salary_slip_bulk_download",
    title: "給与明細",
  },
  {
    id: "bonus_slip_bulk_download",
    title: "賞与明細",
  },
  {
    id: "withholding_tax_certificate_bulk_download",
    title: "源泉徴収票",
  },
  {
    id: "roster_of_workers_bulk_download",
    title: "労働者名簿",
  },
] as ReportTemplate[];

export const userReportTemplates = [
  {
    id: "salary_slip_single_download",
    title: "給与・賞与明細",
    isAvailable: (policies) => {
      if (!policies.report_manager?.some((_) => _ === "GET")) return false;
      if (policies.user_data_manager?.some((_) => _ === "GET")) return true;
      return ["salary", "bonus"].every((_) => policies?.[`user_data_manager/${_}`]?.some((_) => _ === "GET"));
    },
  },
  {
    id: "withholding_tax_single_download",
    title: "源泉徴収票",
    isAvailable: (policies) => {
      if (!policies.report_manager?.some((_) => _ === "GET")) return false;
      if (policies.user_data_manager?.some((_) => _ === "GET")) return true;
      return ["withholding_tax", "withholding_tax_recipient"].every((_) =>
        policies?.[`user_data_manager/${_}`]?.some((_) => _ === "GET")
      );
    },
  },
  {
    id: "roster_of_workers_single_download",
    title: "労働者名簿",
    isAvailable: (policies) => {
      if (!policies.report_manager?.some((_) => _ === "GET")) return false;
      if (policies.user_data_manager?.some((_) => _ === "GET")) return true;
      return policies?.["user_data_manager/personal"]?.some((_) => _ === "GET");
    },
  },
] as ReportTemplate[];
