import { useCallback, useEffect, useMemo } from "react";
import { Container, Row, Col, Alert, Button, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getFileProjects, unselectFileProjects, selectFileState, downloadFile } from "./fileSlice";
import { selectUserState } from "../login/userSlice";
import Sidebar from "../../component/Sidebar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Icon from "../../component/Icon";
import { setLoading } from "../notification/notificationSlice";
import {
  getPersonal,
  getSalaryAndBonus,
  getUserReportTemplates,
  getWithholdingTax,
  selectReportState,
  singleDownloadReportByBaseDate,
  singleDownloadReportByValidFrom,
} from "../report/reportSlice";
import { Link, useNavigate } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, policies } = useAppSelector(selectUserState);
  const { fileProjects } = useAppSelector(selectFileState);
  const {
    userInitialized,
    userReportTemplates: reportTemplates,
    withholdingTaxMetaData,
    salaryMetaData,
    personalData,
  } = useAppSelector(selectReportState);

  useEffect(() => {
    if (userInitialized || !user.id || Object.keys(policies).length === 0) return;
    dispatch(getUserReportTemplates(policies));
  }, [user, policies, userInitialized]);

  useEffect(() => {
    if (!userInitialized) return;
    const promises = [];
    const today = dayjs();
    // 権限があるレポートのみ取得
    reportTemplates.forEach(({ id }) => {
      if (id === "roster_of_workers_single_download") {
        promises.push(dispatch(getPersonal({ accountId: user.id, baseDate: today })));
      } else if (id === "salary_slip_single_download") {
        promises.push(dispatch(getSalaryAndBonus({ accountId: user.id, before: today })));
      } else if (id === "withholding_tax_single_download") {
        promises.push(dispatch(getWithholdingTax({ accountId: user.id, before: today })));
      }
    });
    // ファイルプロジェクトの権限がある場合のみ取得
    if (policies.file_project_manager?.some((_) => _ === "GET")) {
      promises.push(dispatch(getFileProjects({})));
    }
    if (promises.length === 0) return;

    dispatch(setLoading(true));
    Promise.all(promises).finally(() => dispatch(setLoading(false)));

    return () => {
      dispatch(unselectFileProjects());
    };
  }, [userInitialized]);

  const userTimeZone = useMemo(() => {
    return user.current_company.timezone;
  }, [user]);

  const _renderReports = useCallback(
    (id: string) => {
      if (id === "roster_of_workers_single_download") {
        return (
          <>
            {personalData.length > 0 ? (
              <div className="--bullet">
                <a
                  className="--inline-link --cursor-pointer"
                  onClick={async (e) => {
                    dispatch(setLoading(true));
                    await dispatch(
                      singleDownloadReportByBaseDate({
                        resource: "roster_of_workers_single_download",
                        accountId: user.id,
                        baseDate: dayjs(),
                      })
                    );
                    dispatch(setLoading(false));
                  }}
                >
                  労働者名簿
                </a>
              </div>
            ) : (
              <div>ダウンロードできる内容がありません。</div>
            )}
          </>
        );
      } else if (id === "withholding_tax_single_download") {
        return (
          <>
            {withholdingTaxMetaData.slice(0, 2).map((d, i) => {
              return (
                <div key={`withholding_tax_${i}`} className="--bullet">
                  <a
                    className="--inline-link --cursor-pointer"
                    onClick={async () => {
                      dispatch(setLoading(true));
                      await dispatch(
                        singleDownloadReportByValidFrom({
                          resource: d.downloadResource,
                          accountId: d.accountId,
                          validFrom: dayjs(d.validFrom),
                        })
                      );
                      dispatch(setLoading(false));
                    }}
                  >
                    {d.title}
                  </a>
                </div>
              );
            })}
            {withholdingTaxMetaData.length > 0 ? (
              <div className="mt-2">
                <Link to="/_/file/list/withholding/">
                  <Button variant="outline-primary" size="sm">
                    全ての源泉徴収票を見る
                  </Button>
                </Link>
              </div>
            ) : (
              <div>ダウンロードできる内容がありません。</div>
            )}
          </>
        );
      } else if (id === "salary_slip_single_download") {
        return (
          <>
            {salaryMetaData.slice(0, 2).map((d, i) => {
              return (
                <div key={`salary_${i}`} className="--bullet">
                  <a
                    className="--inline-link --cursor-pointer"
                    onClick={async () => {
                      dispatch(setLoading(true));
                      await dispatch(
                        singleDownloadReportByValidFrom({
                          resource: d.downloadResource,
                          accountId: d.accountId,
                          validFrom: dayjs(d.validFrom),
                        })
                      );
                      dispatch(setLoading(false));
                    }}
                  >
                    {d.title}
                  </a>
                </div>
              );
            })}
            {salaryMetaData.length > 0 ? (
              <div className="mt-2">
                <Link to="/_/file/list/salary/">
                  <Button variant="outline-primary" size="sm">
                    全ての給与・賞与明細を見る
                  </Button>
                </Link>
              </div>
            ) : (
              <div>ダウンロードできる内容がありません。</div>
            )}
          </>
        );
      }
    },
    [dispatch, user.id, personalData, withholdingTaxMetaData, salaryMetaData]
  );

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current="fileOutput" />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">ファイル出力</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                {user.id > 0
                  ? reportTemplates.map((reportTemplate) => {
                      return (
                        <Card className="my-1" key={reportTemplate.id}>
                          <Card.Header className="--bold">{reportTemplate.title}</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col>{_renderReports(reportTemplate.id)}</Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    })
                  : null}
                {fileProjects.map(({ id, name, description, start_time, end_time, files }) => {
                  const displyStartTime = dayjs(start_time).tz(userTimeZone).format("YYYY/MM/DD HH:mm");
                  const displyEndTime = dayjs(end_time).tz(userTimeZone).format("YYYY/MM/DD HH:mm");
                  return (
                    <Card className="my-1" key={id}>
                      <Card.Header>
                        <span className="--bold">{name}</span>
                        {description && (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 50, hide: 50 }}
                            overlay={(props) => (
                              <Tooltip id={`tooltip_${id}`} {...props}>
                                {description}
                              </Tooltip>
                            )}
                          >
                            <span className="ms-1">
                              <Icon width={15} height={15} type="info-circle-fill" />
                            </span>
                          </OverlayTrigger>
                        )}
                        <span className="--font-s">（{`${displyStartTime}~${displyEndTime}`}）</span>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          onClick={async () => {
                            dispatch(setLoading(true));
                            await dispatch(downloadFile({ id, isAdmin: false }));
                            dispatch(setLoading(false));
                          }}
                        >
                          <Icon type="download" width={16} height={16} />
                          一括ダウンロード
                        </Button>
                      </Card.Header>
                      <Card.Body>
                        {files.map(({ name, key }) => {
                          return (
                            <div className="--bullet" key={key}>
                              <a
                                className="--inline-link --cursor-pointer"
                                onClick={async () => {
                                  dispatch(setLoading(true));
                                  await dispatch(downloadFile({ id, key, isAdmin: false }));
                                  dispatch(setLoading(false));
                                }}
                              >
                                {name}
                              </a>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  );
                })}
                {fileProjects.length === 0 && reportTemplates.length === 0 && (
                  <Alert variant="info">表示可能なファイルがありません。</Alert>
                )}
                {/*一時的に全件取得の形とする（offset が指定できないため）
                    <Button
                      variant="outline-secondary"
                      className="mt-2 float-end"
                      disabled={!fileProjectHasMore}
                      onClick={() => dispatch(getFileProjects({ page: fileProjectFetchedPage + 1 }))}
                    >
                      さらに表示（全 {fileProjectTotalCount} 件中 {fileProjects.length} 件表示中）
                    </Button> */}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;
