import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { download } from "../../app/download";
import { RootState } from "../../app/store";
import { SearchCondition } from "../profile/profileValues";
import {
  MasterCodeChoice,
  MasterCodeChoiceForIndividual,
  MasterDataItem,
  MASTER_TABLE_PREFIX,
  MASTER_DATA_LIST_UPTO,
  masterColumnChoices,
} from "./masterDataValues";
import { call } from "../../app/api";
import dayjs from "dayjs";
import { RegularColumn, SectorRegularColumns } from "../client/clientValues";
import { FieldValue, ProfileField, ProfileSubField, ProfileSubFieldDiff } from "../profile/profileFieldValues";

const SLICE_NAME = "masterData";

interface MasterDataState {
  masterDataList: MasterDataItem[];
  previewData: string[][];
  masterCodeChoices: MasterCodeChoice[];
  masterCodeChoicesForIndividual: MasterCodeChoiceForIndividual[];
  profileField: ProfileField;
  masterIdLabels: { id: number; label: string }[];
  previewDataTotalCount: number;
  previewDataHasMore: boolean;
  fetchedPreviewDataPage: number;
}

/*
  state の初期状態
*/
const initialState: MasterDataState = {
  masterDataList: [],
  previewData: [],
  masterCodeChoices: [],
  masterCodeChoicesForIndividual: [],
  profileField: { fieldName: "", table: "", category: "", subFields: [], labelMap: {}, account: { id: -1 } },
  masterIdLabels: [],
  previewDataTotalCount: 0,
  previewDataHasMore: false,
  fetchedPreviewDataPage: 0,
};

const _getTerms = (regularColumns: RegularColumn[], exceptionSubFieldIds?: string[]) => {
  return regularColumns
    .filter((col) => {
      return !exceptionSubFieldIds?.includes(col.id);
    })
    .reduce((obj, col) => ({ ...obj, [col.id]: col.label }), {});
};

/*
  対象者、基準期間、フィールド　を指定し
  表として出力可能な形式のデータを取得する。
*/
export const getMasterListView = createAsyncThunk(
  SLICE_NAME + "/getMasterListView",
  async ({
    fieldIds,
    exceptionSubFieldIds,
    baseDate,
    sectorRegularColumns,
    conditions = {},
    page,
  }: {
    fieldIds: string[];
    exceptionSubFieldIds?: string[];
    baseDate?: string;
    sectorRegularColumns: SectorRegularColumns;
    conditions?: SearchCondition;
    page?: number;
  }) => {
    const fieldId = fieldIds[0];
    const res = await call(
      "get",
      `master_data_manager/${fieldId}`
    )({
      or: conditions?.keyword && [
        { [`${fieldId}_code__contain`]: conditions.keyword, [`${fieldId}_name__contain`]: conditions.keyword },
      ],
      base_date: baseDate,
      sort_by: ["order", `${fieldId}_code`, "valid_from"],
      limit: MASTER_DATA_LIST_UPTO,
      page: page,
    });
    const masterData = res.data.result;
    const tableData = [] as string[][];
    const masterIdLabels = [] as { id: number; label: string }[];
    const previewDataTotalCount = res.data.count;
    const previewDataHasMore = res.data.has_more;
    const fetchedPreviewDataPage = page ?? 1;
    if (masterData.length > 0) {
      const regularColumns = sectorRegularColumns[`${MASTER_TABLE_PREFIX}${fieldId}`];
      const terms = _getTerms(regularColumns, exceptionSubFieldIds);
      if (page === 1) {
        tableData.push(Object.values(terms));
      }
      masterData.forEach((profile: any) => {
        tableData.push(Object.keys(terms).map((key) => profile[key]));
        masterIdLabels.push({
          id: profile["id"],
          label: `${profile[`${fieldId}_code`]}（${profile[`${fieldId}_name`]}）`,
        });
      });
    }
    return { tableData, masterIdLabels, previewDataTotalCount, previewDataHasMore, fetchedPreviewDataPage };
  }
);

export const downloadMasterListView = createAsyncThunk(
  SLICE_NAME + "/downloadMasterListView",
  async ({
    fieldIds,
    exceptionSubFieldIds,
    baseDate,
    sectorRegularColumns,
    conditions = {},
  }: {
    fieldIds: string[];
    exceptionSubFieldIds?: string[];
    baseDate?: string;
    sectorRegularColumns: SectorRegularColumns;
    conditions?: SearchCondition;
  }) => {
    const fieldId = fieldIds[0];
    const regularColumns = sectorRegularColumns[`${MASTER_TABLE_PREFIX}${fieldId}`];
    const res = await call(
      "get",
      `master_data_manager/${fieldId}`
    )({
      ...conditions,
      base_date: baseDate,
      sort_by: ["order", `${fieldId}_code`, "valid_from"],
      format: "xlsx",
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

/*
  基準日、sector を指定し、code, name の選択肢、label名を返す
*/
export const getMasterCodeChoices = createAsyncThunk(
  SLICE_NAME + "/getMasterCodeChoices",
  async ({
    baseDate,
    labelData,
  }: {
    baseDate: dayjs.Dayjs;
    labelData: { [key: string]: { code: string; name: string } };
  }) => {
    let codeChoices: MasterCodeChoice[] = [];
    await Promise.all(
      Object.keys(labelData).map(async (label) => {
        const result = await call(
          "get",
          `master_data_manager/${label}`
        )({
          base_date: baseDate.format("YYYY-MM-DD"),
        });
        return { [label]: result.data.result };
      })
    ).then((result) => {
      // すべての非同期処理の結果を一度に処理
      result.forEach((res) => {
        Object.entries(res).forEach(([id, records]) => {
          const codesNames = {} as { [key: string]: string };
          records.forEach((record: { [key: string]: string }) => {
            codesNames[record[`${id}_code`]] = record[`${id}_name`];
          });
          codeChoices = [
            ...codeChoices,
            {
              label: labelData[id],
              id,
              codesNames: codesNames,
            },
          ];
        });
      });
    });
    return codeChoices;
  }
);

/*
  個別画面で、基準日、sector を指定し、code, name の選択肢を返す
*/
export const getMasterCodeChoicesForIndividual = createAsyncThunk(
  SLICE_NAME + "/getMasterCodeChoicesForIndividual",
  async ({ baseDate, sectors }: { baseDate: dayjs.Dayjs; sectors: string[] }) => {
    let codeChoices: MasterCodeChoiceForIndividual[] = [];
    await Promise.all(
      sectors.map(async (sector) => {
        const result = await call(
          "get",
          `master_data_manager/${sector}`
        )({
          base_date: baseDate.format("YYYY-MM-DD"),
          sort_by: ["order"],
        });
        const dataResult = result.data.result;
        let categories = [] as string[];
        // カテゴリを保持している場合、選択肢の並び順が欲しいのでフィールド情報を取得
        if (dataResult.length > 0 && Object.keys(dataResult[0]).some((key) => key === "category_type")) {
          const fullSector = `${MASTER_TABLE_PREFIX}${sector}`;
          const res = await call("get", "profile_manager/field")({ sector: fullSector });
          const defaultCategories = masterColumnChoices[fullSector]?.category_type ?? [];
          const column = res.data.result.find((col: any) => col.name === "category_type");
          categories = column?.options ?? (defaultCategories as string[]);
        }
        return { [sector]: dataResult, categories };
      })
    ).then((result) => {
      // すべての非同期処理の結果を一度に処理
      sectors.forEach((sector, i) => {
        const records = result[i][sector];
        const categories = result[i].categories;
        const options = records.map((r: { [key: string]: string }) => {
          const nameKey = sector === "section" ? `full_${sector}_name` : `${sector}_name`;
          return {
            code: r[`${sector}_code`],
            name: r[nameKey],
            valid_to: r["valid_to"],
            category_type: r["category_type"],
            category_order: r["category_type"] && categories?.indexOf(r["category_type"]),
          };
        });
        codeChoices = [
          ...codeChoices,
          {
            sector,
            options,
          },
        ];
      });
    });
    return codeChoices;
  }
);

// 編集内容が既存のデータと重複していないかチェックする。stateに入れない
export const checkDuplication = createAsyncThunk(
  SLICE_NAME + "/checkDuplication",
  async ({ table, conditions }: { table: string; conditions?: {} }) => {
    const res = await call("get", `master_data_manager/${table}`)({ ...conditions });
    const result: { [key: string]: boolean } = {
      isDuplicated: res.data.result.length > 0,
    };
    return result;
  }
);

export const commitMasterData = createAsyncThunk(
  SLICE_NAME + "/commitMasterData",
  async ({ sectorId, diff }: { sectorId: string; diff: ProfileSubFieldDiff[] }) => {
    const updateDataPerRecords: {
      [recordId: number]: { [field: string]: FieldValue };
    } = {};
    diff.forEach(({ subFieldName, value, recordId, toDelete }) => {
      updateDataPerRecords[recordId] = updateDataPerRecords[recordId] || {};
      if (toDelete === true) {
        updateDataPerRecords[recordId].toDelete = true;
      } else {
        updateDataPerRecords[recordId][subFieldName] = value === "" ? null : value;
      }
    });
    await Promise.all(
      Object.keys(updateDataPerRecords).map((recordId) => {
        const id = +recordId;
        const updateData = updateDataPerRecords[id];
        const method = (() => {
          if (id < 0) return updateData.toDelete !== true ? "post" : "";
          else return updateData.toDelete ? "delete" : "put";
        })();
        if (!method) {
          return new Promise<void>((resolve) => resolve());
        }
        const body = (() => {
          if (method === "put") {
            return { ...updateData, id };
          } else if (method === "post") {
            const valid_from = updateData.valid_from ? `${updateData.valid_from}` : dayjs().format("YYYY-MM-DD");
            return { ...updateData, valid_from };
          } else {
            return { id }; // delete
          }
        })();
        return call(method, `master_data_manager/${sectorId}`)(body);
      })
    );
  }
);

// レコードをProfileFieldの形で取得する
export const getMasterRecordAsProfileField = createAsyncThunk(
  SLICE_NAME + "/getMasterRecordAsProfileField",
  async ({
    sectorId,
    id,
    columns,
    forUpdate = false,
  }: {
    sectorId: string;
    id: number;
    columns: RegularColumn[];
    forUpdate?: boolean;
  }) => {
    const res = await call("get", `master_data_manager/${sectorId}`)({ id });
    return _toProfileField(sectorId, columns, res.data.result[0], forUpdate);
  }
);

// マスタレコードを削除する
export const deleteMasterRecord = createAsyncThunk(
  SLICE_NAME + "/deleteMasterRecord",
  async ({ sectorId, id }: { sectorId: string; id: number }) => {
    await call("delete", `master_data_manager/${sectorId}`)({ id });
  }
);

// 循環参照していないか確認する
export const checkCircularReference = createAsyncThunk(
  SLICE_NAME + "/checkCircularReference",
  async ({ sector, parentCode, code }: { sector: string; parentCode: string; code: string }) => {
    const res = await call("get", `master_data_manager/${sector}`)({ [`${sector}_code`]: parentCode });
    const fullCodes = res.data.result.map((r: any) => r[`full_${sector}_code`]);
    // 出向元会社コード情報は「出向元会社コード=出向元グループ会社コード」のみエラー
    const result: { [key: string]: boolean } = {
      isCircularReference:
        sector === "seconded_from_company"
          ? parentCode === code
          : fullCodes.some((f: string) => f.includes(`/${code}/`)),
    };
    return result;
  }
);

const _toProfileField = (
  sectorId: string,
  columns: RegularColumn[],
  data?: { [key: string]: FieldValue },
  forUpdate?: boolean
) => {
  let labelMap = {};
  const valid_from = (forUpdate && data?.["valid_from"]) || "";
  const valid_to = (forUpdate && data?.["valid_to"]) || "";
  const id = (forUpdate && data?.["id"]) || -1;
  const record = { id, created_at: "", updated_at: "", updated_by: null, valid_from, valid_to };
  const columnChoices = masterColumnChoices[`${MASTER_TABLE_PREFIX}${sectorId}`];
  const subFields = columns
    .filter(({ id }) => !["valid_from", "valid_to", "parent_section_name"].includes(id))
    .map(({ id, required, label, isKey, input_type, reference_field, rules, virtual_field, options }) => {
      labelMap = { ...labelMap, [id]: label };
      return {
        id,
        type: input_type,
        value: data?.[id] ?? "",
        // いずれ廃止の方向。デフォルト値は一律バックエンドで扱い、フロントからの送信値は null にする
        defaultValue: null,
        editable: (() => {
          if (virtual_field) return false; // virtual_field は常に編集不可
          if (!data) return !reference_field; // 完全新規登録の場合、参照項目でなければ編集可能
          return !isKey && !reference_field; // キー項目または参照項目は編集不可
        })(),
        errorMessage: "",
        entered: false,
        required,
        rules,
        labelValueOptions:
          input_type === "options" &&
          (options?.map((o) => ({ label: o, value: o })) ??
            columnChoices?.[id]?.map((label) => ({
              label,
              value: label,
            })) ??
            []),
        virtual_field,
        termsKey: `${MASTER_TABLE_PREFIX}${sectorId}__${id}`,
        tag: "",
        tagGroupsToUse: [],
        tagGroupIndex: 0,
        minTagGroupsLength: 1,
        maxTagGroupsLength: 1,
        record,
      } as ProfileSubField;
    });
  return {
    fieldName: sectorId,
    table: sectorId,
    category: sectorId,
    subFields,
    labelMap: { ja: labelMap },
    account: { id: -1 },
  };
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearMasterCodeChoices: (state) => {
      state.masterCodeChoices = [];
      return state;
    },
    clearMasterCodeChoicesForIndividual: (state) => {
      state.masterCodeChoicesForIndividual = [];
      return state;
    },
    createEmptyProfile: (state, action: PayloadAction<{ sectorId: string; columns: RegularColumn[] }>) => {
      const { sectorId, columns } = action.payload;
      state.profileField = _toProfileField(sectorId, columns);
      return state;
    },
    stageProfileSubFields: (state, action) => {
      const subFields = [...action.payload.subFields];
      state.profileField = { ...state.profileField, subFields };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMasterListView.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.previewData =
        action.payload.fetchedPreviewDataPage === 1
          ? action.payload.tableData
          : [...state.previewData, ...action.payload.tableData];
      state.masterIdLabels =
        action.payload.fetchedPreviewDataPage === 1
          ? action.payload.masterIdLabels
          : [...state.masterIdLabels, ...action.payload.masterIdLabels];
      state.previewDataTotalCount = action.payload.previewDataTotalCount;
      state.previewDataHasMore = action.payload.previewDataHasMore;
      state.fetchedPreviewDataPage = action.payload.fetchedPreviewDataPage;
    });
    builder.addCase(getMasterCodeChoices.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.masterCodeChoices = action.payload;
    });
    builder.addCase(getMasterCodeChoicesForIndividual.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.masterCodeChoicesForIndividual = action.payload;
    });
    builder.addCase(getMasterRecordAsProfileField.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.profileField = action.payload;
    });
  },
});
export const {
  clearMasterCodeChoices,
  clearMasterCodeChoicesForIndividual,
  createEmptyProfile,
  stageProfileSubFields,
} = slice.actions;

export const selectMasterDataState = (state: RootState) => {
  return state.masterData as MasterDataState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
