import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Modal, ListGroup } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getRegularColumns, selectClientState, getReportItem, commitReportItem } from "./clientSlice";
import ModalDialog from "../../component/ModalDialog";
import { useParams } from "react-router-dom";
import { ItemColumn } from "./clientValues";
import Table from "../../component/Table";
import Icon from "../../component/Icon";

type CategoryType = { label: string; categoryType: string };

type CategoryTypes = {
  [reportType: string]: CategoryType[];
};

type SectorIds = {
  [reportType: string]: string[];
};

type ItemColumnWithLabel = ItemColumn & { label: string };

const CATEGORY_TYPES: CategoryTypes = {
  salary_slip: [
    { label: "勤怠", categoryType: "days_times" },
    { label: "支給", categoryType: "payments" },
    { label: "控除", categoryType: "deductions" },
    { label: "その他", categoryType: "others" },
  ],
  bonus_slip: [
    { label: "支給", categoryType: "payments" },
    { label: "控除", categoryType: "deductions" },
    { label: "その他", categoryType: "others" },
  ],
};

const SECTOR_IDS: SectorIds = {
  salary_slip: ["profile_u_attendance", "profile_u_salary"],
  bonus_slip: ["profile_u_bonus"],
};

function ReportOrderEdit() {
  const { target } = useParams();
  const { sectorRegularColumns, reportItem } = useAppSelector(selectClientState);
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    isConfirming: false,
    editingCategory: "",
    editingOrder: [] as ItemColumnWithLabel[],
  });

  const reportType = useMemo(() => {
    return target ?? "";
  }, [target]);

  useEffect(() => {
    if (reportType) {
      Promise.all(
        SECTOR_IDS[reportType]?.map((sectorId) => {
          if (!sectorRegularColumns[sectorId]) {
            return dispatch(getRegularColumns({ sectorId }));
          }
        }) ?? []
      ).then(() => dispatch(getReportItem({ reportType })));
    }
  }, [reportType]);

  const itemsWithLabel: { [categoryType: string]: ItemColumnWithLabel[] } = useMemo(() => {
    const itemsWithLabel: { [categoryType: string]: ItemColumnWithLabel[] } = {};
    CATEGORY_TYPES[reportType]?.forEach((c) => {
      if (Array.isArray(reportItem["items"][c.categoryType])) {
        itemsWithLabel[c.categoryType] = reportItem["items"][c.categoryType]
          .map((item) => {
            let label = "";
            SECTOR_IDS[reportType]?.forEach((sectorId) => {
              sectorRegularColumns[sectorId]?.forEach((column) => {
                if (column.id === item.column) {
                  label = column.label;
                }
              });
            });
            return { ...item, label };
          })
          .filter((item) => item.label); // 利用していないカラムは除外
      }
    });
    return itemsWithLabel;
  }, [reportItem]);
  const closeModal = () => {
    $state({ isConfirming: false, editingCategory: "", editingOrder: [] });
  };

  const moveIndex = (value: number, index: number) => {
    if (state.editingCategory === "") return;
    const current = state.editingOrder;
    const next = [...current];
    const toTurn = index + value; // 今移動先にある列。選択した列の直前か直後
    current.forEach((_, i) => {
      if (i === index) next[toTurn] = _;
      else if (i === toTurn) next[index] = _;
      else next[i] = _;
    });
    $state({ ...state, editingOrder: next });
  };
  return (
    <Container>
      <div className="pt-4">
        <h2 className="Headline--section mb-2">並び順の設定</h2>
        <Table
          col={[
            { name: "カテゴリ", width: 100 },
            { name: "並び順", width: 700 },
            { name: "アクション", width: 120 },
          ]}
          row={(CATEGORY_TYPES[reportType] ?? []).map((c) => {
            return {
              data: [
                c.label,
                (itemsWithLabel[c.categoryType] ?? [])
                  .filter((_) => _.label)
                  .map((item) => item.label)
                  .join(", ") ?? "",
                <>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      $state({
                        ...state,
                        editingCategory: c.categoryType,
                        editingOrder: itemsWithLabel[c.categoryType] ?? [],
                      });
                    }}
                  >
                    編集
                  </Button>
                </>,
              ],
            };
          })}
        />
      </div>
      <ModalDialog
        show={state.isConfirming}
        message="保存します。よろしいですか？"
        onConfirm={async () => {
          await dispatch(
            commitReportItem({
              reportType: reportType,
              items: {
                ...reportItem.items,
                [state.editingCategory]: state.editingOrder.map(({ label, ...column }) => column),
              },
            })
          );
          dispatch(getReportItem({ reportType: reportType }));
          closeModal();
        }}
        onCancel={() => {
          $state({ ...state, isConfirming: false });
        }}
      />
      <Modal show={state.editingCategory !== ""} onHide={closeModal} size="lg" centered>
        <Modal.Body>
          <Row className="mb-1">
            <Col md={12} className="--overflow-auto">
              <ListGroup>
                {state.editingOrder
                  .filter((_) => _.label)
                  .map(({ column, label }, i) => {
                    return (
                      <ListGroup.Item key={column}>
                        <Row className="--align-items-center">
                          <Col md={10}>{label}</Col>
                          <Col md={2}>
                            <Button variant="link" disabled={i === 0} onClick={() => moveIndex(-1, i)}>
                              <Icon width={15} height={15} type="caret-up-fill" />
                            </Button>
                            <Button
                              variant="link"
                              disabled={i === state.editingOrder.length - 1}
                              onClick={() => moveIndex(1, i)}
                            >
                              <Icon width={15} height={15} type="caret-down-fill" />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button disabled={state.editingOrder.length === 0} onClick={() => $state({ ...state, isConfirming: true })}>
            保存
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ReportOrderEdit;
