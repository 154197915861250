export interface Section {
  id: number;
  name: string;
  sectionCode: string;
  parentSectionCode: string | null;
  valid_from: string;
  valid_to: string;
  order: number;
}

export interface SectionTreeNode {
  id: number;
  name: string;
  sectionCode: string;
  parentSectionCode: string;
  children: SectionTreeNode[];
  count: number[];
  order: number;
}

export interface Position {
  id: number;
  positionName: string;
  positionCode: string;
  valid_from: string;
  valid_to: string;
  order: number;
}

export const getSectionTree = (sections: Section[]) => {
  let nodes: SectionTreeNode[] = sections.map((s) => {
    return {
      id: s.id,
      name: s.name,
      sectionCode: s.sectionCode,
      parentSectionCode: s.parentSectionCode,
      children: [],
      count: [],
      order: s.order,
    } as SectionTreeNode;
  });
  nodes.forEach((n) => {
    if (n.parentSectionCode) {
      const parent = nodes.find((node) => node.sectionCode === n.parentSectionCode);
      if (parent) {
        parent.children.push(n);
      }
    }
  });
  nodes.forEach((n) => {
    n.children.sort((a, b) => a.order - b.order);
  });
  let result = nodes
    .filter((n) => !n.parentSectionCode)
    .map((n) => {
      return {
        id: n.id,
        name: n.name,
        sectionCode: n.sectionCode,
        children: n.children,
        count: n.count,
        order: n.order,
      };
    }) as SectionTreeNode[];
  return result;
};
