const data = {
  category: "calculation_unit_price",
  table: "calculation_unit_price",
  subFields: {
    calculation_unit_price_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_base_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_allowance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    family_benefit_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment11_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment12_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment13_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment14_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment15_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment16_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment17_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment18_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment19_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment20_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment21_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment22_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment23_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment24_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment25_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment26_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_payment27_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction11_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction12_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction13_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction14_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction15_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction16_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction17_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction18_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction19_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction20_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction21_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction22_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction23_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction24_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction25_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction26_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction27_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction28_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction29_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_deduction30_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment11_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment12_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment13_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment14_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment15_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment16_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment17_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment18_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment19_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment20_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment21_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment22_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment23_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment24_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment25_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment26_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment27_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment28_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment29_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_payment30_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction11_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction12_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction13_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction14_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction15_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction16_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction17_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction18_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction19_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction20_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction21_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction22_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction23_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction24_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction25_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction26_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction27_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction28_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction29_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_deduction30_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      calculation_unit_price_history: "計算単価履歴",
    },
  },
};

export default data;
