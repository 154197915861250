import { useState, useEffect, useMemo, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import {
  TODO_STATUS,
  TARGET,
  SendMailCondition,
  Account,
  getMaxDate,
  SYSTEM_MAIL_ADDRESS,
  MailSettingDetail,
} from "./mailSettingValues";
import {
  getWholeProjects,
  getWorkgroups,
  getAccounts,
  clearWorkgroups,
  removeWorkgroup,
  selectMailSettingState,
  registerMailSetting,
} from "./mailSettingSlice";
import { getMails, selectMailState } from "../mail/mailSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { selectUserState } from "../login/userSlice";
import { showToast } from "../notification/notificationSlice";
import { Row, Col, Button, ListGroup, Badge, Card, Form, Modal } from "react-bootstrap";
import Table from "../../component/Table";
import ModalDialog from "../../component/ModalDialog";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { EMAIL_FORMAT_REGEX } from "../../app/validator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { wholeProjects, workgroups, accounts } = useAppSelector(selectMailSettingState);
  const { mails } = useAppSelector(selectMailState);
  const current_company = useAppSelector(selectCurrentCompany);
  const { user } = useAppSelector(selectUserState);
  const hasInitialized = useRef(false);

  const getInitialEta = () => {
    let initialEta = dayjs().add(1, "hour");
    initialEta = initialEta.add(5 - (initialEta.minute() % 5), "minute");
    return initialEta.toDate();
  };

  const [state, $state] = useState({
    name: "",
    mailTemplateId: "",
    eta: getInitialEta(),
    fromName: "",
    replyTo: "",
    cc: [] as string[],
    bcc: [] as string[],
    target: "workflow",
    conditions: [] as SendMailCondition[],
    selectedAccounts: [] as Account[],
    activeModal: "",
    timeoutId: null as number | null,
    inputKeyword: "", // 入力キーワード
    searchKeyword: "", // 検索用キーワード（入力し終わって500ms経過）
  });

  useEffect(() => {
    if (current_company.id) {
      dispatch(getMails({ conditions: {} }));
    }
  }, [current_company, dispatch]);

  useEffect(() => {
    dispatch(getWholeProjects());
  }, [dispatch]);

  useEffect(() => {
    if (user && user.account_name && user.mail_address && !hasInitialized.current) {
      $state((prevState) => ({
        ...prevState,
        fromName: user.account_name,
        replyTo: user.mail_address,
      }));
      hasInitialized.current = true;
    }
  }, [user]);

  useEffect(() => {
    if (!state.mailTemplateId && mails.length > 0) {
      $state((prevState) => ({
        ...prevState,
        mailTemplateId: mails[0].id,
      }));
    }
  }, [mails]);

  useEffect(() => {
    if (state.activeModal === "search_accounts") {
      const conditions = state.searchKeyword ? { keyword: state.searchKeyword } : {};
      dispatch(getAccounts({ conditions }));
    }
  }, [state.activeModal, state.searchKeyword]);

  const userTimeZone = useMemo(() => {
    return current_company.timezone ?? "Asia/Tokyo";
  }, [current_company]);

  const errorMessages = useMemo(() => {
    const errorMessages = [] as { place: string; message: string }[];
    if (state.name === "") errorMessages.push({ place: "name", message: "入力してください" });
    if (dayjs().isSameOrAfter(dayjs(state.eta)))
      errorMessages.push({ place: "eta", message: "未来の日時を入力してください" });
    if (state.fromName === "") errorMessages.push({ place: "fromName", message: "入力してください" });
    if (state.replyTo === "") errorMessages.push({ place: "replyTo", message: "入力してください" });
    else if (!EMAIL_FORMAT_REGEX.test(state.replyTo))
      errorMessages.push({ place: "replyTo", message: "メールアドレスの入力形式が不正です" });
    state.cc.forEach((cc, i) => {
      if (cc === "") errorMessages.push({ place: `cc_${i}`, message: "入力してください" });
      else if (!EMAIL_FORMAT_REGEX.test(cc))
        errorMessages.push({ place: `cc_${i}`, message: "メールアドレスの入力形式が不正です" });
    });
    state.bcc.forEach((bcc, i) => {
      if (bcc === "") errorMessages.push({ place: `bcc_${i}`, message: "入力してください" });
      else if (!EMAIL_FORMAT_REGEX.test(bcc))
        errorMessages.push({ place: `bcc_${i}`, message: "メールアドレスの入力形式が不正です" });
    });
    if (
      state.conditions.length < 1 ||
      state.conditions.some(
        (c) =>
          (state.target === "account" && c.id__in?.length < 1 && !c.remarks) ||
          (state.target === "workflow" &&
            ([c.project_id, c.work_group_id, c.workflow_id, c.service_id].some((id) => !id) || c.status.length < 1))
      )
    )
      errorMessages.push({ place: "conditions", message: "設定してください" });
    return errorMessages;
  }, [state]);

  const addItem = (listName: keyof typeof state) => {
    if (Array.isArray(state[listName])) {
      const list = [
        ...(state[listName] as string[] | SendMailCondition[]),
        listName === "conditions"
          ? {
              id__in: [],
              remarks: "",
              project_id: 0,
              work_group_id: 0,
              workflow_id: 0,
              service_id: 0,
              status: [],
            }
          : "",
      ];
      $state({ ...state, [listName]: list });
    }
  };

  const removeItem = (listName: keyof typeof state, index: number) => {
    if (Array.isArray(state[listName])) {
      const list = [...(state[listName] as string[] | SendMailCondition[])];
      list.splice(index, 1);
      $state({ ...state, [listName]: list });
    }
  };

  const register = async () => {
    const res = await dispatch(
      registerMailSetting({
        name: state.name,
        mailTemplateId: state.mailTemplateId,
        eta: dayjs(state.eta).clone().utc().format("YYYY-MM-DDTHH:mm:00.000[Z]"),
        fromName: state.fromName,
        fromAddress: SYSTEM_MAIL_ADDRESS,
        replyTo: state.replyTo,
        cc: state.cc,
        bcc: state.bcc,
        target: state.target,
        conditions: state.conditions,
      })
    );
    $state({ ...state, activeModal: "" });
    const registeredMailSetting = res.payload as MailSettingDetail;
    dispatch(
      showToast({
        id: `toast-${Date.now()}`,
        content: `${registeredMailSetting.name}を登録しました。`,
      })
    );
    navigate(`/_/mail_setting/detail/${registeredMailSetting.id}`);
  };

  return (
    <>
      <Row className="mb-1">
        <Col>
          <span className="--required-label"></span> は必須項目です。
        </Col>
      </Row>
      <ListGroup className="mb-4">
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4}>
              <div className="--bold --required-label">タイトル</div>
            </Col>
            <Col md={8}>
              <Form.Control
                type="text"
                value={state.name}
                onChange={(e) => {
                  $state({ ...state, name: e.target.value });
                }}
              />
              {(() => {
                const m = errorMessages?.find((message) => message.place === "name");
                return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
              })()}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4}>
              <div className="--bold --required-label">メール文面</div>
            </Col>
            <Col md={8}>
              <Row>
                <Col md={6}>
                  <Form.Select
                    value={state.mailTemplateId}
                    onChange={(e) => $state({ ...state, mailTemplateId: e.target.value })}
                  >
                    {mails.map((m) => {
                      return (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4}>
              <div className="--bold --required-label">送信日時</div>
            </Col>
            <Col md={8}>
              <Row>
                <Col md={4}>
                  <DatePicker
                    maxDate={getMaxDate().tz(userTimeZone).endOf("day").toDate()}
                    dateFormat={"yyyy-MM-dd HH:mm"}
                    selected={dayjs(state.eta).tz(userTimeZone).toDate()}
                    showTimeSelect
                    className="form-control"
                    timeIntervals={5}
                    onChange={(selected) => {
                      if (!selected) return;
                      $state({ ...state, eta: selected });
                    }}
                  />
                </Col>
              </Row>
              {(() => {
                const m = errorMessages?.find((message) => message.place === "eta");
                return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
              })()}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4}>
              <div className="--bold --required-label">差出人名</div>
            </Col>
            <Col md={8}>
              <Form.Control
                type="text"
                value={state.fromName}
                onChange={(e) => {
                  $state({ ...state, fromName: e.target.value });
                }}
              />
              {(() => {
                const m = errorMessages?.find((message) => message.place === "fromName");
                return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
              })()}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4}>
              <div className="--bold">差出人メールアドレス</div>
            </Col>
            <Col md={8}>
              <div className="--pre-wrap">{SYSTEM_MAIL_ADDRESS}</div>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4}>
              <div className="--bold --required-label">返信先メールアドレス（管理者）</div>
            </Col>
            <Col md={8}>
              <Form.Control
                type="text"
                value={state.replyTo}
                onChange={(e) => {
                  $state({ ...state, replyTo: e.target.value });
                }}
              />
              {(() => {
                const m = errorMessages?.find((message) => message.place === "replyTo");
                return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
              })()}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4}>
              <div className="--bold">CC</div>
            </Col>
            <Col md={8}>
              {state.cc.map((cc, i) => (
                <div key={`cc_${i}`} className="mb-2">
                  <Row className="g-2">
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        value={cc}
                        onChange={(e) => {
                          const next = [...state.cc];
                          next[i] = e.target.value;
                          $state({ ...state, cc: next });
                        }}
                      />
                    </Col>
                    <Col md={6} className="--flex --align-items-center">
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => {
                          removeItem("cc", i);
                        }}
                      >
                        削除
                      </Button>
                    </Col>
                  </Row>
                  {(() => {
                    const m = errorMessages?.find((message) => message.place === `cc_${i}`);
                    return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                  })()}
                </div>
              ))}
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  addItem("cc");
                }}
              >
                CCを追加
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4}>
              <div className="--bold">BCC</div>
            </Col>
            <Col md={8}>
              {state.bcc.map((bcc, i) => (
                <div key={`bcc_${i}`} className="mb-2">
                  <Row className="g-2">
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        value={bcc}
                        onChange={(e) => {
                          const next = [...state.bcc];
                          next[i] = e.target.value;
                          $state({ ...state, bcc: next });
                        }}
                      />
                    </Col>
                    <Col md={6} className="--flex --align-items-center">
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => {
                          removeItem("bcc", i);
                        }}
                      >
                        削除
                      </Button>
                    </Col>
                  </Row>
                  {(() => {
                    const m = errorMessages?.find((message) => message.place === `bcc_${i}`);
                    return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                  })()}
                </div>
              ))}
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  addItem("bcc");
                }}
              >
                BCCを追加
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
      <ListGroup className="mb-4">
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={4} className="--flex --align-items-center">
              <div className="--bold">送信条件</div>
              {(() => {
                const m = errorMessages?.find((message) => message.place === "conditions");
                return m ? (
                  <div className="--pre-wrap ms-2">
                    <Badge pill bg="danger" className="me-1">
                      !
                    </Badge>
                    <span className="--text-annotation">{m.message}</span>
                  </div>
                ) : null;
              })()}
            </Col>
            <Col md={8}>
              {TARGET.map((t) => (
                <Form.Check
                  type="radio"
                  id={`target_${t.value}`}
                  key={`target_${t.value}`}
                  label={t.name}
                  value={t.value}
                  checked={state.target === t.value}
                  onChange={(e) => {
                    $state({ ...state, target: e.target.value, conditions: [], selectedAccounts: [] });
                    if (e.target.value === "workflow") {
                      dispatch(clearWorkgroups());
                    }
                  }}
                  inline
                />
              ))}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          {(() => {
            switch (state.target) {
              case "workflow":
                return (
                  <>
                    {state.conditions.map((condition, index) => (
                      <Card key={`condition_${index}`} className="MailSettingConditions-card">
                        <Card.Body>
                          <Row className="mb-2">
                            <Col xs={4}>
                              <div className="MailSettingConditions-card__name">プロジェクト</div>
                            </Col>
                            <Col xs={3}>
                              <div className="MailSettingConditions-card__name">フェーズ</div>
                            </Col>
                            <Col xs={5}>
                              <div className="MailSettingConditions-card__name">ステータス</div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={4}>
                              <Form.Select
                                value={condition.project_id}
                                onChange={(e) => {
                                  const projectId = Number(e.target.value);
                                  const next = [...state.conditions];
                                  next[index] = {
                                    ...next[index],
                                    project_id: projectId,
                                    workflow_id:
                                      wholeProjects.find((p) => p.project_id === projectId)?.workflow_id ?? 0,
                                    service_id: wholeProjects.find((p) => p.project_id === projectId)?.service_id ?? 0,
                                  };
                                  $state({ ...state, conditions: next });
                                  if (e.target.value !== "0") {
                                    dispatch(getWorkgroups(next));
                                  } else {
                                    dispatch(removeWorkgroup({ index }));
                                  }
                                }}
                              >
                                <option value={0}>---</option>
                                {wholeProjects.map((p) => {
                                  return (
                                    <option key={`project_${index}_${p.project_id}`} value={p.project_id}>
                                      {p.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            <Col xs={3}>
                              <Form.Select
                                value={condition.work_group_id}
                                onChange={(e) => {
                                  const workgroupId = Number(e.target.value);
                                  const next = [...state.conditions];
                                  next[index] = { ...next[index], work_group_id: workgroupId };
                                  $state({ ...state, conditions: next });
                                }}
                              >
                                <option value={0}>---</option>
                                {workgroups[index]?.map((w) => {
                                  return (
                                    <option key={`work_group_${index}_${w.value}`} value={w.value}>
                                      {w.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            <Col xs={4}>
                              {TODO_STATUS.map((s) => {
                                return (
                                  <Form.Check
                                    inline
                                    type="checkbox"
                                    id={`todo_status_${index}_${s.value}`}
                                    key={`todo_status_${index}_${s.value}`}
                                    label={s.name}
                                    checked={condition.status.includes(s.value)}
                                    className="mx-2"
                                    onChange={() => {
                                      const next = [...state.conditions];
                                      next[index] = {
                                        ...next[index],
                                        status: next[index].status.includes(s.value)
                                          ? next[index].status.filter((_s) => _s !== s.value)
                                          : [...next[index].status, s.value],
                                      };
                                      $state({ ...state, conditions: next });
                                    }}
                                  />
                                );
                              })}
                            </Col>
                            <Col xs={1}>
                              {index > 0 && (
                                <Button
                                  variant="outline-danger"
                                  className="float-end"
                                  size="sm"
                                  onClick={() => {
                                    removeItem("conditions", index);
                                    dispatch(removeWorkgroup({ index }));
                                  }}
                                >
                                  削除
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className={classNames({ "mt-2": state.conditions.length > 0 })}
                      onClick={() => {
                        addItem("conditions");
                      }}
                    >
                      送信条件を追加
                    </Button>
                  </>
                );
              case "account":
                return (
                  <Card className="MailSettingConditions-card">
                    <Card.Body>
                      <Row className="mb-2">
                        <Col xs={4}>
                          <div className="MailSettingConditions-card__name">ログインIDで絞り込む</div>
                        </Col>
                        <Col xs={8}>
                          <div className="MailSettingConditions-card__name">備考で絞り込む</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Button
                            variant="outline-primary"
                            className="mb-2"
                            onClick={() => {
                              $state({ ...state, activeModal: "search_accounts" });
                            }}
                          >
                            検索する
                          </Button>
                          <ul>
                            {state.selectedAccounts.map((a) => (
                              <li key={a.id} className="ms-3 --pre-wrap MailSettingConditions-card__account-item">
                                <span className="me-2">
                                  {a.name}({a.login_code})
                                </span>
                                <Button
                                  variant="outline-danger"
                                  className="MailSettingConditions-card__account-item-delete"
                                  size="sm"
                                  onClick={() => {
                                    const nextConditions = [...state.conditions];
                                    nextConditions[0] = {
                                      ...nextConditions[0],
                                      id__in: (nextConditions[0]?.id__in || []).filter((id) => id !== a.id),
                                    };
                                    const nextSelectedAccounts = state.selectedAccounts.filter((_a) => _a.id !== a.id);
                                    $state({
                                      ...state,
                                      conditions: nextConditions,
                                      selectedAccounts: nextSelectedAccounts,
                                    });
                                  }}
                                >
                                  削除
                                </Button>
                              </li>
                            ))}
                          </ul>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={state.conditions[0]?.remarks ?? ""}
                            placeholder="キーワードを入力"
                            onChange={(e) => {
                              const next = [...state.conditions];
                              next[0] = { ...next[0], remarks: e.target.value };
                              $state({ ...state, conditions: next });
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mt-2 --pre-wrap">
                            <Badge pill bg="info" className="me-2">
                              !
                            </Badge>
                            <span>ログインIDと備考はAND条件です。</span>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                );
              default:
                return null;
            }
          })()}
        </ListGroup.Item>
      </ListGroup>
      <Row className="mb-1">
        <Col>
          <Button
            onClick={() => {
              $state({ ...state, activeModal: "before_register" });
            }}
            disabled={errorMessages.length > 0}
            className="mx-2"
          >
            登録
          </Button>
        </Col>
      </Row>
      <Modal
        show={state.activeModal === "search_accounts"}
        onHide={() => {
          $state({ ...state, activeModal: "" });
        }}
        size="lg"
        centered
      >
        <Modal.Body>
          <Row className="mb-1">
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    value={state.inputKeyword}
                    className="mb-2"
                    placeholder="キーワードを入力"
                    onChange={(e) => {
                      const keyword = e.target.value;
                      // 打ち終わって500ms後に検索のリクエストをする
                      if (state.timeoutId) {
                        window.clearTimeout(state.timeoutId);
                      }
                      const timeoutId = window.setTimeout(() => {
                        $state({ ...state, timeoutId: null, searchKeyword: keyword, inputKeyword: keyword });
                      }, 500);
                      $state({ ...state, timeoutId, inputKeyword: keyword });
                    }}
                  />
                </Col>
              </Row>
              <div className="ms-1 mt-1">
                検索結果：{accounts.length}件中 {accounts.length}件表示中
              </div>
              <Table
                col={[
                  {
                    name: "ログインID",
                    colName: "login_code",
                    width: 200,
                  },
                  {
                    name: "名前",
                    width: 200,
                  },
                  {
                    name: "メールアドレス",
                    width: 250,
                  },
                  {
                    name: "アクション",
                    width: 100,
                  },
                ]}
                row={accounts.map((r) => {
                  return {
                    id: r.id,
                    key: r.id,
                    data: [
                      r.login_code,
                      r.name,
                      r.mail_address,
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => {
                          const nextConditions = [...state.conditions];
                          nextConditions[0] = {
                            ...nextConditions[0],
                            id__in: (nextConditions[0]?.id__in || []).includes(r.id)
                              ? nextConditions[0].id__in.filter((id) => id !== r.id)
                              : [...(nextConditions[0]?.id__in || []), r.id],
                          };
                          const nextSelectedAccounts = state.selectedAccounts.some((a) => a.id === r.id)
                            ? state.selectedAccounts.filter((a) => a.id !== r.id)
                            : [...state.selectedAccounts, r];
                          $state({
                            ...state,
                            conditions: nextConditions,
                            selectedAccounts: nextSelectedAccounts,
                          });
                        }}
                      >
                        追加
                      </Button>,
                    ],
                    appendAfter: {
                      login_code:
                        r.is_billing === false ? (
                          <div className="mx-1 badge rounded-pill bg-info">テスト用</div>
                        ) : r.is_guest ? (
                          <div className="mx-1 badge rounded-pill bg-success">ゲスト</div>
                        ) : null,
                    },
                  };
                })}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              $state({ ...state, activeModal: "" });
            }}
            variant="outline-secondary"
          >
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={state.activeModal === "before_register"}
        message="登録します。よろしいですか？"
        onConfirm={register}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
      />
    </>
  );
}

export default App;
