const data = {
  category: "industrial_accident",
  table: "industrial_accident",
  subFields: {
    industrial_accident_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    office_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    office_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    industrial_accident_insurance_business_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    employee_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      industrial_accident_history: "労災保険履歴",
    },
  },
};

export default data;
