import React from "react";
import Login from "./features/login/Login";
import InitPassword from "./features/login/InitPassword";
import ResetPassword from "./features/login/ResetPassword";
import DashboardSwitcher from "./features/dashboard/DashboardSwitcher";
import OperationDashboardDetail from "./features/dashboard/OperationDashboardDetail";
import AdminDashboard from "./features/dashboard/CustomerSuccessDashboard";
import ApplyTop from "./features/apply/ApplyTop";
import ApplyCreate from "./features/apply/ApplyCreate";
import ApplyDetail from "./features/apply/ApplyDetail";
import StatisticsTop from "./features/statistics/StatisticsTop";
import PermissionTop from "./features/permission/PermissionTop";
import PermissionMemberDetail from "./features/permission/PermissionMemberDetail";
import CreateClient from "./features/client/CreateClient";
import ImportList from "./features/client/ImportList";
import ImportGuide from "./features/client/ImportGuide";
import ImportClientInitSectorData from "./features/client/ImportClientInitSectorData";
import ImportSupport from "./features/client/ImportSupport";
import PropertyTop from "./features/client/PropertyTop";
import Root from "./component/Root";
import NoRole from "./features/error/NoRole";
import NoMatchedView from "./features/error/NoMatchedView";
import SessionTimeOutView from "./features/error/SessionTimeOutView";
import MasterTop from "./features/profile/MasterTop";
import ProfileListTop from "./features/profile/ProfileListTop";
import ProfileDetailView from "./features/profile/ProfileDetailView";
import MyAccountSettingGateway from "./features/login/MyAccountSettingGateway";
import SwitchCompany from "./features/login/SwitchCompany";
import Salary from "./features/report/Salary";
import Bonus from "./features/report/Bonus";
import WithHolding from "./features/report/WithHolding";
import CommentTop from "./features/dashboard/CommentTop";
import TodoList from "./features/todo/TodoList";
import AccountTop from "./features/profile/AccountTop";
import AccountDetail from "./features/profile/AccountDetail";
import MyNumberTop from "./features/myNumber/MyNumberTop";
import MyNumberListView from "./features/myNumber/MyNumberListView";
import MyNumberAdminDetailView from "./features/myNumber/MyNumberAdminDetailView";
import MyNumberUserDetailView from "./features/myNumber/MyNumberUserDetailView";
import MyNumberDetail from "./features/myNumber/MyNumberDetail";
import MyNumberUpload from "./features/myNumber/MyNumberUpload";
import MyNumberDownloadLogs from "./features/myNumber/MyNumberDownloadLogs";
import ApplicationConfigPreview from "./features/apply/ApplicationConfigPreview";
import MailTop from "./features/mail/MailTop";
import MailDetail from "./features/mail/MailDetail";
import MailSettingTop from "./features/mailSetting/MailSettingTop";
import MailSettingDetail from "./features/mailSetting/MailSettingDetail";
import MailHistoryList from "./features/mailSetting/MailHistoryList";
import FileOutput from "./features/file/FileOutput";
import MyAccountTop from "./features/myAccount/MyAccountTop";
import MyPasswordUpdate from "./features/myAccount/MyPasswordUpdate";
import FileManagementTop from "./features/file/FileManagementTop";

const ROUTING_MAP = [
  { path: "", element: <Login />, role: [] },
  { path: "/_/init", element: <InitPassword />, role: [] },
  { path: "/_/reset/:ot", element: <ResetPassword />, role: [] },
  {
    path: "/_/dashboard/",
    element: <Root content={<DashboardSwitcher />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/dashboard/detail/",
    element: <Root content={<OperationDashboardDetail />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/apply/",
    element: <Root content={<ApplyTop />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/apply/:mode/",
    element: <Root content={<ApplyTop />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/apply/edit/:applicantId/:formId/",
    element: <Root content={<ApplyDetail />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/apply/create/:applicationType/",
    element: <Root content={<ApplyCreate />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/statistics/",
    element: <Root content={<StatisticsTop />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/myaccount/setting/",
    element: <Root content={<MyAccountSettingGateway />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/masters/",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/application/preview/:target/",
    element: <ApplicationConfigPreview />,
    role: ["admin"],
  },
  {
    path: "/_/masters/:mode",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/:mode/:target",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/fields/edit/:target",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/fields/options/:target",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/external/edit/:target",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/external/options/:target",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/payroll_setting/edit/",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/reports/order/:target",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/role/create",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/role/edit/:roleName",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/role/priority",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/remind_setting/edit/",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/masters/remind_setting/create",
    element: <Root content={<MasterTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/property/",
    element: <Root content={<PropertyTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/property/:mode",
    element: <Root content={<PropertyTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/profiles/",
    element: <Root content={<ProfileListTop />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/profiles/:mode",
    element: <Root content={<ProfileListTop />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/profile/",
    element: <Root content={<ProfileDetailView />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/profile/:memberId/",
    element: <Root content={<ProfileDetailView />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/my_number/",
    element: <Root content={<MyNumberTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/my_number/user",
    element: <Root content={<MyNumberUserDetailView />} />,
    role: ["user"],
  },
  {
    path: "/_/my_number/user/edit/:myNumberId",
    element: <Root content={<MyNumberDetail />} />,
    role: ["user"],
  },
  {
    path: "/_/my_number/admin",
    element: <Root content={<MyNumberListView />} />,
    role: ["admin"],
  },
  {
    path: "/_/my_number/admin/:accountId",
    element: <Root content={<MyNumberAdminDetailView />} />,
    role: ["admin"],
  },
  {
    path: "/_/my_number/admin/:accountId/edit/:myNumberId",
    element: <Root content={<MyNumberDetail />} />,
    role: ["admin"],
  },
  {
    path: "/_/my_number/upload",
    element: <Root content={<MyNumberUpload />} />,
    role: ["admin"],
  },
  {
    path: "/_/my_number/download_log",
    element: <Root content={<MyNumberDownloadLogs />} />,
    role: ["admin"],
  },
  {
    path: "/_/permission/",
    element: <Root content={<PermissionTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/permission/:mode",
    element: <Root content={<PermissionTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/permission/account/:memberId/",
    element: <Root content={<PermissionMemberDetail />} />,
    role: ["admin"],
  },
  {
    path: "/_/client/",
    element: <Root content={<AdminDashboard />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/client/create",
    element: <Root content={<CreateClient />} />,
    role: ["admin"],
  },
  {
    path: "/_/import/",
    element: <Root content={<ImportList />} />,
    role: ["admin"],
  },
  {
    path: "/_/import_guide/",
    element: <Root content={<ImportGuide />} />,
    role: ["admin"],
  },
  {
    path: "/_/import_support/",
    element: <Root content={<ImportSupport />} />,
    role: ["admin"],
  },
  {
    path: "/_/import/:sectorId/",
    element: <Root content={<ImportClientInitSectorData />} />,
    role: ["admin"],
  },
  {
    path: "/_/districted/:user_id/",
    element: <Root content={<ProfileDetailView />} />,
    role: ['admin.districted.{"user_id": "match"}'],
  },
  {
    path: "/_/file/list/salary/",
    element: <Root content={<Salary />} />,
    role: ["user"],
  },
  {
    path: "/_/file/list/salary/:accountId",
    element: <Root content={<Salary />} />,
    role: ["user"],
  },
  {
    path: "/_/file/list/bonus/",
    element: <Root content={<Bonus />} />,
    role: ["user"],
  },
  {
    path: "/_/file/list/bonus/:accountId",
    element: <Root content={<Bonus />} />,
    role: ["user"],
  },
  {
    path: "/_/file/list/withholding/",
    element: <Root content={<WithHolding />} />,
    role: ["user"],
  },
  {
    path: "/_/file/list/withholding/:accountId",
    element: <Root content={<WithHolding />} />,
    role: ["user"],
  },
  {
    path: "/_/comments/",
    element: <Root content={<CommentTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/switch_company/",
    element: <Root content={<SwitchCompany />} />,
    role: ["admin"],
  },
  {
    path: "/_/todo/",
    element: <Root content={<TodoList />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/account/",
    element: <Root content={<AccountTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/account/detail/:accountId",
    element: <Root content={<AccountDetail />} />,
    role: ["admin"],
  },
  {
    path: "/_/account/detail/:accountId/:mode",
    element: <Root content={<AccountDetail />} />,
    role: ["admin"],
  },
  {
    path: "/_/account/:field",
    element: <Root content={<AccountTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/mail/",
    element: <Root content={<MailTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/mail/detail/:id",
    element: <Root content={<MailDetail />} />,
    role: ["admin"],
  },
  {
    path: "/_/mail/:field",
    element: <Root content={<MailTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/mail_setting/",
    element: <Root content={<MailSettingTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/mail_setting/detail/:id",
    element: <Root content={<MailSettingDetail />} />,
    role: ["admin"],
  },
  {
    path: "/_/mail_setting/:field",
    element: <Root content={<MailSettingTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/mail_setting/history/:id",
    element: <Root content={<MailHistoryList />} />,
    role: ["admin"],
  },
  {
    path: "/_/files",
    element: <Root content={<FileManagementTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/files/:mode",
    element: <Root content={<FileManagementTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/files/:mode/:id",
    element: <Root content={<FileManagementTop />} />,
    role: ["admin"],
  },
  {
    path: "/_/file",
    element: <Root content={<FileOutput />} />,
    role: [],
  },
  {
    path: "/_/no_enough_role/",
    element: <Root content={<NoRole />} />,
    role: [],
  },
  {
    path: "/_/session_timeout/",
    element: <Root content={<SessionTimeOutView />} />,
    role: [],
  },
  {
    path: "/_/myaccount/",
    element: <Root content={<MyAccountTop />} />,
    role: ["admin", "user"],
  },
  {
    path: "/_/myaccount/password",
    element: <Root content={<MyPasswordUpdate />} />,
    role: ["admin", "user"],
  },
  { path: "*", element: <Root content={<NoMatchedView />} />, role: [] },
];
export default ROUTING_MAP;
