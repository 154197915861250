import { LangDictionary } from "../../app/translate";
const Dictionary = {
  // 申請書のステータス
  APPLICATION_STATUS_todo: { ja: "申請前", en: "Before applying" },
  APPLICATION_STATUS_running: { ja: "申請中", en: "Applying" },
  APPLICATION_STATUS_rejected: { ja: "差し戻し中", en: "Rejected" },
  APPLICATION_STATUS_rejected_to_applicant: { ja: "申請者へ差し戻し中", en: "Rejected to Applicant" },
  APPLICATION_STATUS_copied: { ja: "転記済", en: "Copied" },
  APPLICATION_STATUS_done: { ja: "完了", en: "Done" },
  // 申請書のステップのステータス
  APPLICATION_STEP_STATUS_todo: { ja: "承認前", en: "Before processing" },
  APPLICATION_STEP_STATUS_running: { ja: "承認処理中", en: "Processing" },
  APPLICATION_STEP_STATUS_rejected: { ja: "差し戻し中", en: "Rejected" },
  APPLICATION_STEP_STATUS_done: { ja: "承認済", en: "Approved" },
  // 申請書の操作の種類
  APPLICATION_ACTION_create: { ja: "新規作成", en: "create" },
  APPLICATION_ACTION_update: { ja: "更新", en: "update" },
  APPLICATION_ACTION_apply: { ja: "申請", en: "apply" },
  APPLICATION_ACTION_approve: { ja: "承認", en: "approve" },
  APPLICATION_ACTION_reject: { ja: "差し戻し", en: "reject" },
  APPLICATION_ACTION_pullback: { ja: "引き戻し", en: "pullback" },
  // 申請書のステッパーの内容
  APPLY_STEPPER_create: { ja: "作成", en: "Create" },
  APPLY_STEPPER_input: { ja: "入力", en: "Input" },
  APPLY_STEPPER_apply: { ja: "申請", en: "Apply" },
  APPLY_STEPPER_approve: { ja: "承認", en: "Approve" },
  // 申請書カテゴリ名（仮）
  APPLICATION_CATEGORY_entrance_resignation: { ja: "入退社", en: "Entrance and Resignation" },
  APPLICATION_CATEGORY_personnel_change: { ja: "身上変更", en: "Personnel Change" },
  APPLICATION_CATEGORY_salary: { ja: "給与", en: "Salary" },
  APPLICATION_CATEGORY_spouse: { ja: "配偶者", en: "Spouse" },
  APPLICATION_CATEGORY_dependent: { ja: "家族", en: "Dependent" },
  APPLICATION_CATEGORY_childcare: { ja: "育児", en: "Childcare" },
  APPLICATION_CATEGORY_health_care: { ja: "介護", en: "Health Care" },
  APPLICATION_CATEGORY_other_leave: { ja: "休職・復職（育児介護以外）", en: "Other Leave" },
  APPLICATION_CATEGORY_employment_agreement: { ja: "労働条件変更/契約", en: "Employment Agreement" },
  APPLICATION_CATEGORY_skill: { ja: "免許取得・研修", en: "Skill" },
  APPLICATION_CATEGORY_others: { ja: "その他", en: "Others" },
  // 一覧のヘッダ
  APPLICATION_LIST_HEADER_status: { ja: "ステータス", en: "status" },
  APPLICATION_LIST_HEADER_applicant: { ja: "申請者", en: "applicant" },
  APPLICATION_LIST_HEADER_created_at: { ja: "作成日", en: "created at" },
  APPLICATION_LIST_HEADER_applied_at: { ja: "申請日", en: "applied at" },
  APPLICATION_LIST_HEADER_completed_at: { ja: "完了日", en: "completed at" },
  APPLICATION_LIST_HEADER_updated_at: { ja: "最終更新日", en: "updated at" },
  APPLICATION_LIST_HEADER_role: { ja: "役割", en: "role" },
  APPLICATION_LIST_HEADER_application_type: { ja: "申請内容", en: "application type" },
  APPLICATION_LIST_HEADER_detail: { ja: "詳細", en: "detail" },
  // トップのタブ
  APPLICATION_TAB_create: { ja: "新規作成", en: "create" },
  APPLICATION_TAB_representative_create: { ja: "代理作成", en: "representative create" },
  APPLICATION_TAB_applicant: { ja: "自分の申請", en: "my application" },
  APPLICATION_TAB_representative_applicant: { ja: "代理作成した申請", en: "representative application" },
  APPLICATION_TAB_processor: { ja: "提出された申請", en: "be applied" },
  APPLICATION_TAB_admin: { ja: "全申請", en: "all" },

  // フィールドタイプ
  SUB_FIELD_TYPE_string: { ja: "テキスト入力", en: "text input" },
  SUB_FIELD_TYPE_longtext: { ja: "テキスト入力（複数行）", en: "text input(multiple line)" },
  SUB_FIELD_TYPE_number: { ja: "数値（整数）入力", en: "integer number input" },
  SUB_FIELD_TYPE_float: { ja: "数値（小数）入力", en: "decimal number input" },
  SUB_FIELD_TYPE_number__calculation: { ja: "計算", en: "calculation" },
  SUB_FIELD_TYPE_boolean: { ja: "チェックボックス（はい/いいえ）", en: "check box(yes/no)" },
  SUB_FIELD_TYPE_postCode: { ja: "郵便番号入力", en: "postcode input" },
  SUB_FIELD_TYPE_bankNumber: { ja: "銀行コード", en: "bank code input" },
  SUB_FIELD_TYPE_bankBranchNumber: { ja: "支店コード", en: "branch code input" },
  SUB_FIELD_TYPE_bankAccountNo: { ja: "口座番号", en: "account number input" },
  SUB_FIELD_TYPE_bankAccountNameKana: { ja: "口座名義（カナ）", en: "account name kana input" },
  SUB_FIELD_TYPE_date: { ja: "日付入力", en: "date input" },
  SUB_FIELD_TYPE_time: { ja: "時刻入力", en: "time input" },
  SUB_FIELD_TYPE_email: { ja: "メールアドレス入力", en: "mail address input" },
  SUB_FIELD_TYPE_staticText: { ja: "テキスト表示", en: "static text" },
  SUB_FIELD_TYPE_staticLine: { ja: "区切り線", en: "section line" },
  SUB_FIELD_TYPE_phoneNumber: { ja: "電話番号", en: "phone number input" },
  SUB_FIELD_TYPE_file: { ja: "添付ファイル", en: "file input" },
  SUB_FIELD_TYPE_nameKana: { ja: "氏名カナ", en: "name kana input" },
  SUB_FIELD_TYPE_addressKana: { ja: "住所カナ", en: "address kana input" },
  SUB_FIELD_TYPE_yearMonth: { ja: "年月選択", en: "year month select" },
  SUB_FIELD_TYPE_options: { ja: "選択肢", en: "options" },
  SUB_FIELD_TYPE_checkbox: { ja: "チェックボックス（複数選択）", en: "checkbox(multiple)" },
  SUB_FIELD_TYPE_staticLabel: { ja: "ラベル", en: "label" },
  // 承認者の決定方法
  APPLICATION_PROCESSOR_fixed: { ja: "申請者によらず固定", en: "fixed" },
  APPLICATION_PROCESSOR_related: { ja: "申請者によって変動", en: "related" },
  // 申請書のみで使用する注釈表示
  EDITING_ANNOTATION_bank_account__date_of_change: {
    ja: "本申請書の変更を適用したい年月を指定してください。",
    en: "",
  },
  EDITING_ANNOTATION_bank_account_1only__date_of_change: {
    ja: "本申請書の変更を適用したい年月を指定してください。",
    en: "",
  },
  EDITING_ANNOTATION_spouse__date_of_change: {
    ja: "本申請書の変更を適用したい年月日を指定してください。",
    en: "",
  },
  EDITING_ANNOTATION_account__name: {
    ja: "現行と変更がない場合も入力してください",
    en: "Enter even if no change from current",
  },
  EDITING_ANNOTATION_account__name_kana: {
    ja: "現行と変更がない場合も入力してください",
    en: "Enter even if no change from current",
  },
  EDITING_ANNOTATION_account__name_en: {
    ja: "現行と変更がない場合も入力してください",
    en: "Enter even if no change from current",
  },
  EDITING_ANNOTATION_name_edit__legal_name: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "現行と変更がない場合も入力してください",
    en: "Enter even if no change from current",
  },
  EDITING_ANNOTATION_name_edit__legal_name_kana: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "現行と変更がない場合も入力してください",
    en: "Enter even if no change from current",
  },
  EDITING_ANNOTATION_entrance__basic_pension_number: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "不明な場合は記入不要です",
    en: "If unknown, you do not need to fill in this form.",
  },
  EDITING_ANNOTATION_entrance__employment_insurance_number: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "不明な場合は記入不要です",
    en: "If unknown, you do not need to fill in this form.",
  },
  EDITING_ANNOTATION_entrance__memo: {
    ja: "基礎年金や雇用保険番号が不明の場合はその旨を明記してください。",
    en: "If the basic pension or employment insurance number is unknown, please state so.",
  },
  EDITING_ANNOTATION_maternity_leave__3_1_maternity_situation: {
    ja: "出生時育児休業を２回に分割取得する場合は、１回目と２回目を一括で申し出ること",
    en: "If you want to take maternity leave twice, apply for both at the same time.",
  },
  EDITING_ANNOTATION_maternity_leave__4_1_maternity_situation: {
    ja: "出生時育児休業を２回に分割取得する場合は、１回目と２回目を一括で申し出ること",
    en: "If you want to take maternity leave twice, apply for both at the same time.",
  },
  EDITING_ANNOTATION_dependent__date_of_change: {
    ja: "本申請書の変更を適用したい年月日を指定してください。",
    en: "",
  },
  EDITING_ANNOTATION_dependent_registration2__tax_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "税扶養区分が「有」の場合必須です",
    en: "Required if tax_dependent_type is 有",
  },
  EDITING_ANNOTATION_dependent_registration2__insurance_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「加入」の場合必須です",
    en: "Required if insurance_dependent_type is 加入",
  },
  EDITING_ANNOTATION_dependent_registration2__insurance_dependent_on_reason_type: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「加入」の場合必須です",
    en: "Required if insurance_dependent_type is 加入",
  },
  EDITING_ANNOTATION_dependent_registration2__insurance_dependent_on_other_reason: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養加入理由が「その他」の場合必須です",
    en: "Required if insurance_dependent_on_reason_type is その他",
  },
  EDITING_ANNOTATION_dependent_edit2__tax_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "税扶養区分が「有」の場合必須です",
    en: "Required if tax_dependent_type is 有",
  },
  EDITING_ANNOTATION_dependent_edit2__loss_of_tax_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "税扶養区分が「無」かつ税扶養年月日が設定されている場合必須です",
    en: "Required if tax_dependent_type is 無 and tax_dependent_on is not empty",
  },
  EDITING_ANNOTATION_dependent_edit2__insurance_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「加入」の場合必須です",
    en: "Required if insurance_dependent_type is 加入",
  },
  EDITING_ANNOTATION_dependent_edit2__insurance_dependent_on_reason_type: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「加入」の場合必須です",
    en: "Required if insurance_dependent_type is 加入",
  },
  EDITING_ANNOTATION_dependent_edit2__insurance_dependent_on_other_reason: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養加入理由が「その他」の場合必須です",
    en: "Required if insurance_dependent_on_reason_type is その他",
  },
  EDITING_ANNOTATION_dependent_edit2__loss_of_insurance_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「未加入」かつ健保扶養加入日が設定されている場合必須です",
    en: "Required if insurance_dependent_type is 加入 and insurance_dependent_on is not empty",
  },
  EDITING_ANNOTATION_dependent_edit2__insurance_dependent_loss_reason_type: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「未加入」かつ健保扶養加入日が設定されている場合必須です",
    en: "Required if insurance_dependent_type is 加入 and insurance_dependent_on is not empty",
  },
  EDITING_ANNOTATION_dependent_edit2__insurance_dependent_loss_other_reason: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養資格喪失理由が「その他」の場合必須です",
    en: "Required if insurance_dependent_loss_reason_type is その他",
  },
  EDITING_ANNOTATION_spouse_registration__tax_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "税扶養区分が「有」の場合必須です",
    en: "Required if tax_dependent_type is 有",
  },
  EDITING_ANNOTATION_spouse_registration__insurance_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「加入」の場合必須です",
    en: "Required if insurance_dependent_type is 加入",
  },
  EDITING_ANNOTATION_spouse_registration__insurance_dependent_on_reason_type: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「加入」の場合必須です",
    en: "Required if insurance_dependent_type is 加入",
  },
  EDITING_ANNOTATION_spouse_registration__insurance_dependent_on_other_reason: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養加入理由が「その他」の場合必須です",
    en: "Required if insurance_dependent_on_reason_type is その他",
  },
  EDITING_ANNOTATION_spouse_edit__tax_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "税扶養区分が「有」の場合必須です",
    en: "Required if tax_dependent_type is 有",
  },
  EDITING_ANNOTATION_spouse_edit__loss_of_tax_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "税扶養区分が「無」かつ税扶養年月日が設定されている場合必須です",
    en: "Required if tax_dependent_type is 無 and tax_dependent_on is not empty",
  },
  EDITING_ANNOTATION_spouse_edit__insurance_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「加入」の場合必須です",
    en: "Required if insurance_dependent_type is 加入",
  },
  EDITING_ANNOTATION_spouse_edit__insurance_dependent_on_reason_type: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「加入」の場合必須です",
    en: "Required if insurance_dependent_type is 加入",
  },
  EDITING_ANNOTATION_spouse_edit__insurance_dependent_on_other_reason: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養加入理由が「その他」の場合必須です",
    en: "Required if insurance_dependent_on_reason_type is その他",
  },
  EDITING_ANNOTATION_spouse_edit__loss_of_insurance_dependent_on: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「未加入」かつ健保扶養加入日が設定されている場合必須です",
    en: "Required if insurance_dependent_type is 加入 and insurance_dependent_on is not empty",
  },
  EDITING_ANNOTATION_spouse_edit__insurance_dependent_loss_reason_type: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養区分が「未加入」かつ健保扶養加入日が設定されている場合必須です",
    en: "Required if insurance_dependent_type is 加入 and insurance_dependent_on is not empty",
  },
  EDITING_ANNOTATION_spouse_edit__insurance_dependent_loss_other_reason: {
    // 申請書にのみ表示の実装がまだのため、定義のみ
    ja: "健保扶養資格喪失理由が「その他」の場合必須です",
    en: "Required if insurance_dependent_loss_reason_type is その他",
  },
  EDITING_ANNOTATION_other_leave__reason_for_other_leave: {
    ja: "休職事由が「その他自己都合」の場合の詳細",
    en: "Details when the reason for leave is 'other personal reasons'",
  },
  EDITING_ANNOTATION_other_leave__relation_file_id: {
    ja: "疾病休暇の場合は、診断書の写し（PDF）を添付",
    en: "For sick leave, attach a copy of the medical certificate (PDF)",
  },
  EDITING_ANNOTATION_other_reinstatment__relation_file_id: {
    ja: "疾病休暇の場合は、診断書の写し（PDF）を添付",
    en: "For sick leave, attach a copy of the medical certificate (PDF)",
  },
  EDITING_ANNOTATION_other_leave_modification__relation_file_id: {
    ja: "疾病休暇の場合は、診断書の写し（PDF）を添付",
    en: "For sick leave, attach a copy of the medical certificate (PDF)",
  },
  EDITING_ANNOTATION_child_care_short_time_work__start_time: {
    ja: "労働者が個々に労働する時間を申し出ることを認める制度である場合には必要となります。",
    en: "If the system allows workers to individually propose their working hours, set start time.",
  },
  EDITING_ANNOTATION_child_care_short_time_work__end_time: {
    ja: "労働者が個々に労働する時間を申し出ることを認める制度である場合には必要となります。",
    en: "If the system allows workers to individually propose their working hours, set end time.",
  },
  // プレースホルダー表示
  PLACEHOLDER_name_edit__reason_detail: {
    ja: "※変更理由がその他の場合は詳細をこちらへ入力してください",
    en: "If the reason for the change is その他, enter the details here.",
  },
  PLACEHOLDER_condolence__relationship_type_detail: {
    ja: "※続柄がその他の場合は詳細をこちらへ入力してください",
    en: "If the relationship for the change is その他, enter the details here.",
  },
  APPLICANT_CONFIG_TYPE_self: { ja: "本人のみ", en: "self" },
  APPLICANT_CONFIG_TYPE_representative: { ja: "代理のみ", en: "representative" },
  APPLICANT_CONFIG_TYPE_all: { ja: "本人＋代理", en: "all" },
} as LangDictionary;
export default Dictionary;
