import { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getPositions, getSections, selectSectionState } from "../section/sectionSlice";
import { SectionTreeNode, getSectionTree } from "../section/sectionValues";
import { getMembers, getPersonalData, selectProfileState } from "../profile/profileSlice";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import dayjs from "dayjs";
import { Member, PersonalData } from "../profile/profileValues";
import columnTypeChoicesJson from "../../column_type_choices.json";

type MemberAndPersonal = {
  member: Member;
  personal?: PersonalData;
};
type SectionTreeNodeWithMembers = SectionTreeNode & {
  members: MemberAndPersonal[];
  children: SectionTreeNodeWithMembers[];
};
const DEFAULT_MEMBER_TO_SHOW = 20;

function SectionTree() {
  const dispatch = useAppDispatch();
  const { sections, positions } = useAppSelector(selectSectionState);
  const { members, personals } = useAppSelector(selectProfileState);
  const [openSectionIds, $openSectionIds] = useState([] as number[]);
  const [checkedPositionCodes, $checkedPositionCodes] = useState([] as string[]);
  const [selectedPointDate, $selectedPointDate] = useState(dayjs());
  const [showSectionCode, $showSectionCode] = useState(false);
  const [checkedEmploymentTypes, $checkedEmploymentTypes] = useState(
    columnTypeChoicesJson.employment.map((p) => {
      return p.label;
    })
  );
  const [showDetail, $showDetail] = useState(true);
  const [showPortrait, $showPortrait] = useState(true);
  const employmentTypeOptions = useMemo(() => {
    return columnTypeChoicesJson.employment.map((p) => ({
      label: p.label,
      value: p.label,
    }));
  }, [columnTypeChoicesJson]);
  const [showRecessMember, $showRecessMember] = useState(false);
  const [showSecondedMember, $showSecondedMember] = useState(false);
  useEffect(() => {
    dispatch(getSections({ baseDate: selectedPointDate }));
    dispatch(getMembers());
    dispatch(getPositions({ baseDate: selectedPointDate }));
    dispatch(getPersonalData());
  }, [selectedPointDate]);
  const [expandedSections, $expandedSections] = useState([] as string[]);

  const personalsLoaded = useMemo(() => {
    return Object.keys(personals).length > 0;
  }, [personals]);

  const sortedPositions = useMemo(() => {
    return positions.map((p) => ({ ...p })).sort((a, b) => a.order - b.order);
  }, [positions]);

  const tree = useMemo(() => {
    const tree = getSectionTree(sections);
    const _ = (node: SectionTreeNode): SectionTreeNodeWithMembers => {
      const thisSectionMembers = members.filter((m) => `${m.sectionCode}` === node.sectionCode);
      const _members = thisSectionMembers
        .filter((m) => {
          let flag = true;
          if (personalsLoaded) {
            if (flag && personals[`${m.id}`]?.enrollment_type === "休職") {
              flag = showRecessMember;
            }
            if (flag && personals[`${m.id}`]?.enrollment_type === "出向") {
              flag = showSecondedMember;
            }
          }
          return flag;
        })
        .map((member) => {
          return {
            member,
            personal: personals[`${member.id}`],
          };
        });
      const count = _members.reduce(
        (prev, current) => {
          const _t = current.personal?.enrollment_type;
          return [
            prev[0] + (_t === "在籍" ? 1 : 0),
            prev[1] + (_t === "休職" ? 1 : 0),
            prev[2] + (_t === "出向" ? 1 : 0),
          ];
        },
        [0, 0, 0]
      );
      return {
        ...node,
        members: _members,
        count,
        children: node.children.map((n) => _(n)) as SectionTreeNodeWithMembers[],
      };
    };
    return tree.map((node) => _(node));
  }, [sections, members, positions, personalsLoaded, checkedEmploymentTypes, showRecessMember, showSecondedMember]);

  const someMemberHasNoPersonalData = useMemo(() => {
    return members.some((m) => personals[`${m.id}`] === undefined);
  }, [members, personals]);

  useEffect(() => {
    if (positions.length > 0) {
      $checkedPositionCodes(positions.map((p) => p.positionCode));
    }
  }, [positions]);

  const getNodeLayout = (node: SectionTreeNodeWithMembers, root: boolean = false) => {
    const employmentMatchedMembers = node.members.filter((m) => {
      return checkedEmploymentTypes.includes(m.personal?.employment_type ?? "");
    });
    return (
      <div
        key={`node${node.id}`}
        className={classNames({
          Tree__node: true,
          "Tree__node--has-children": node.children.length > 0,
          "Tree__node--open": openSectionIds.includes(node.id),
          "--root": root,
        })}
      >
        <div
          className={classNames({
            Tree__item: true,
            "Tree__item--has-children": node.children.length > 0,
            "Tree__item--open": openSectionIds.includes(node.id),
          })}
        >
          {node.children.length > 0 && (
            <div
              onClick={() => {
                if (node.children.length === 0) return;
                openSectionIds.includes(node.id)
                  ? $openSectionIds(openSectionIds.filter((_) => _ !== node.id))
                  : $openSectionIds([...openSectionIds, node.id]);
              }}
              className="Tree__item-toggler"
            ></div>
          )}
          <div className="Tree__item-inner">
            <div className="Tree__box">
              <div className="Tree__box-title">
                <div>
                  <span className="--bold">{node.name}</span>
                  {showSectionCode && <span> / 部署コード : {showSectionCode ? node.sectionCode : ""}</span>}
                </div>
              </div>
              {showDetail && (
                <div className="Tree__box-content">
                  {node.members.length > 0 ? (
                    <div className="border-bottom mb-2">
                      <span>
                        在籍 :{" "}
                        <span className="--bold">
                          {node.count[0] +
                            (showRecessMember ? node.count[1] : 0) +
                            (showSecondedMember ? node.count[2] : 0)}
                        </span>{" "}
                        名
                      </span>
                      {node.count[1] + node.count[2] > 0 && (showRecessMember || showSecondedMember) ? "(" : null}
                      {showRecessMember && node.count[1] > 0 ? (
                        <span>
                          休職 : <span className="--bold">{node.count[1]}</span> 名
                        </span>
                      ) : null}
                      {showSecondedMember && node.count[2] > 0 ? (
                        <span>
                          {showRecessMember && node.count[1] > 0 ? " / " : null}出向 :
                          <span className="--bold">{node.count[2]}</span> 名
                        </span>
                      ) : null}
                      {node.count[1] + node.count[2] > 0 ? ")" : null}
                    </div>
                  ) : (
                    <div className="text-secondary">在籍者なし</div>
                  )}
                  {node.members.length > 0 &&
                    (() => {
                      let count = 0;
                      const result = sortedPositions.map((p, pi) => {
                        const membersToDisplay = employmentMatchedMembers.filter((m) => {
                          return checkedPositionCodes.includes(`${m.member.positionCode}`);
                        });
                        if (membersToDisplay.some((m) => `${m.member.positionCode}` === p.positionCode)) {
                          return (
                            <div key={`${p.positionCode}`} className="mt-1 --flex">
                              <div className="Tree__position">{p.positionName}</div>
                              <div className="Tree__members">
                                <Row>
                                  {employmentMatchedMembers
                                    .filter(
                                      (m) =>
                                        `${m.member.positionCode}` === p.positionCode &&
                                        checkedPositionCodes.includes(p.positionCode)
                                    )
                                    .map((m) => {
                                      if (
                                        ++count > DEFAULT_MEMBER_TO_SHOW &&
                                        !expandedSections.includes(node.sectionCode)
                                      )
                                        return;
                                      return (
                                        <Col md={6} key={m.member.id}>
                                          <div className="--flex --align-items-center">
                                            {showPortrait && (
                                              <figure className="Tree__portrait">
                                                {m.member.image && <img src={m.member.image} alt={m.member.name} />}
                                              </figure>
                                            )}
                                            <div className="Tree__member-name">
                                              {m.member.name}
                                              {!m.personal && (
                                                <span className="--text-annotation">(基本情報未登録)</span>
                                              )}
                                            </div>
                                            {m.member.isConcurrent ? (
                                              <div className="Badge--running mx-1">兼務</div>
                                            ) : null}
                                            {m.personal?.enrollment_type === "出向" ? (
                                              <div className="Badge--cancel mx-1">出向</div>
                                            ) : null}
                                            {m.personal?.enrollment_type === "休職" ? (
                                              <div className="Badge--danger mx-1">休職</div>
                                            ) : null}
                                          </div>
                                        </Col>
                                      );
                                    })}
                                </Row>
                                {count > DEFAULT_MEMBER_TO_SHOW && !expandedSections.includes(node.sectionCode) && (
                                  <div className="mt-2">
                                    {pi === sortedPositions.length - 1 ? (
                                      <Button
                                        variant="link"
                                        className="btn-sm"
                                        onClick={() => {
                                          $expandedSections([...expandedSections, node.sectionCode]);
                                        }}
                                      >
                                        省略された社員(
                                        {node.members.length - DEFAULT_MEMBER_TO_SHOW}
                                        名)を表示
                                      </Button>
                                    ) : (
                                      <span>...</span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                      });
                      return result;
                    })()}
                </div>
              )}
            </div>
          </div>
        </div>
        {openSectionIds.includes(node.id) && (
          <div className="Tree__children">
            {node.children.map((n) => getNodeLayout(n as SectionTreeNodeWithMembers))}
          </div>
        )}
      </div>
    );
  };
  return (
    <Container>
      <Row className="mt-4 --align-items-center">
        <Col md="2" className="--bold">
          基準日
        </Col>
        <Col md="10">
          <SnapshotTimeSelector
            selectedPointDate={selectedPointDate}
            useFarthestDaySelector={false}
            useFarthestPastDaySelector={false}
            onChange={({ selectedPointDate }: onChangeOption) => {
              if (!selectedPointDate) return;
              $selectedPointDate(selectedPointDate);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="2" className="--bold">
          表示する情報
        </Col>
        <Col md="10">
          <Form.Check
            type="checkbox"
            label="部署コード"
            id="show_section_code"
            checked={showSectionCode}
            value="showSectionCode"
            onChange={(e) => {
              $showSectionCode(!showSectionCode);
            }}
          />
          <div className="--flex">
            <Form.Check
              type="checkbox"
              label="従業員"
              id="show_detail"
              checked={showDetail}
              value="showDetail"
              onChange={(e) => {
                $showDetail(!showDetail);
              }}
            />
            <span className="mx-1">(</span>
            <Form.Check
              type="checkbox"
              label="画像"
              disabled={!showDetail}
              id="show_portrait"
              checked={showPortrait}
              value="showPortrait"
              onChange={(e) => {
                $showPortrait(!showPortrait);
              }}
            />
            <span>)</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2" className="--bold">
          在籍状況
        </Col>
        <Col md="10">
          {personalsLoaded ? (
            <Row>
              <Col md="4">
                <Form.Check
                  onChange={() => {
                    $showRecessMember(!showRecessMember);
                  }}
                  type="checkbox"
                  label={"休職者を含める"}
                  disabled={!showDetail}
                  id={`show_enrollment_recess`}
                  checked={showRecessMember}
                  value={`showRecessMember`}
                />
              </Col>
              <Col md="4">
                <Form.Check
                  onChange={() => {
                    $showSecondedMember(!showSecondedMember);
                  }}
                  type="checkbox"
                  label={"出向者を含める"}
                  disabled={!showDetail}
                  id={`show_enrollment_seconded`}
                  checked={showSecondedMember}
                  value={`showSecondedMember`}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>データを取得中か、データが不足しています</Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md="2" className="--bold">
          表示する役職
        </Col>
        <Col md="10">
          <Row>
            {sortedPositions.map((p) => {
              return (
                <Col md="4" key={p.positionCode}>
                  <Form.Check
                    onChange={() => {
                      if (checkedPositionCodes.includes(p.positionCode)) {
                        $checkedPositionCodes(checkedPositionCodes.filter((code) => code !== p.positionCode));
                      } else {
                        $checkedPositionCodes([...checkedPositionCodes, p.positionCode]);
                      }
                    }}
                    type="checkbox"
                    label={p.positionName}
                    disabled={!showDetail}
                    id={`show_position_${p.positionCode}`}
                    checked={checkedPositionCodes.includes(p.positionCode)}
                    value={p.positionCode}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2" className="--bold">
          表示する雇用区分
        </Col>
        <Col md="10">
          <Row>
            {personalsLoaded ? (
              employmentTypeOptions.map((p) => {
                return (
                  <Col md="4" key={p.value}>
                    <Form.Check
                      onChange={() => {
                        if (checkedEmploymentTypes.includes(p.value)) {
                          $checkedEmploymentTypes(checkedEmploymentTypes.filter((type) => type !== p.value));
                        } else {
                          $checkedEmploymentTypes([...checkedEmploymentTypes, p.value]);
                        }
                      }}
                      type="checkbox"
                      label={p.label}
                      disabled={!showDetail}
                      id={`show_employment_type_${p.label}`}
                      checked={checkedEmploymentTypes.includes(p.value)}
                      value={p.value}
                    />
                  </Col>
                );
              })
            ) : (
              <div>データを取得中か、データが不足しています</div>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {someMemberHasNoPersonalData && (
            <Alert variant="warning">
              基本情報が登録されていない従業員がいるため、在籍状況が正確に反映されていない場合があります。
            </Alert>
          )}
          <Row className="mb-3">
            <Col>
              <div className="Tree">
                <div className="Tree__nodes">
                  {tree.map((node) => {
                    return getNodeLayout(node, true);
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SectionTree;
