import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form, Alert, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getSectors, initSectorStatus, putSectorStatus, selectClientState } from "../client/clientSlice";
import { SectorStatus, CHANGEABLE_SECTOR_IDS } from "../client/clientValues";
import ModalDialog from "../../component/ModalDialog";
import { USER_TABLE_PREFIX } from "./profileValues";
import { MASTER_TABLE_PREFIX } from "../masterData/masterDataValues";
import Table from "../../component/Table";

function MasterDataList() {
  const dispatch = useAppDispatch();
  const { sectors, SectorStatusId, sectorUserStatus, sectorMasterStatus } = useAppSelector(selectClientState);

  const [state, $state] = useState({
    sectors: [] as SectorStatus[],
    activeModal: "",
    keyword: "",
    isDisplayCodeSector: true,
    isDisplayUserSector: true,
  });
  const renameTargetSectors = useMemo(() => {
    return sectors.filter(({ has_rename_targets }) => has_rename_targets);
  }, [sectors]);

  const optionEditableSectors = useMemo(() => {
    return sectors.filter(({ has_option_editable_columns }) => has_option_editable_columns);
  }, [sectors]);

  const selectSector = (id: string) => {
    const next = state.sectors.map((s) => {
      return {
        ...s,
        active: s.key === id ? !s.active : s.active,
      };
    });
    $state({ ...state, sectors: next });
  };

  useEffect(() => {
    dispatch(initSectorStatus());
    dispatch(getSectors());
  }, [dispatch]);

  useEffect(() => {
    if (sectorUserStatus.length === 0 || sectorMasterStatus.length === 0) return;
    $state({ ...state, sectors: [...sectorMasterStatus, ...sectorUserStatus] });
  }, [sectorUserStatus, sectorMasterStatus]);

  const displaySectors = useMemo(() => {
    return state.sectors.filter((s) => {
      if (!s.label.includes(state.keyword)) return false;
      if (!state.isDisplayCodeSector && s.key.startsWith(MASTER_TABLE_PREFIX)) return false;
      if (!state.isDisplayUserSector && s.key.startsWith(USER_TABLE_PREFIX)) return false;
      return true;
    });
  }, [state.keyword, state.sectors, state.isDisplayCodeSector, state.isDisplayUserSector]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row className="mb-3">
            <Col>
              <p>管理対象のテーブルを選択してください。管理対象はデータインポート後もいつでも変更可能です。</p>
              <Button className="mb-3" onClick={() => $state({ ...state, activeModal: "before_save" })}>
                保存
              </Button>
              <Card className="mb-2">
                <Card.Body>
                  <Row>
                    <span className="--bold">表示条件</span>
                  </Row>
                  <Row className="--align-items-center mb-1">
                    <Col md={2}>テーブル種別：</Col>
                    <Col md={10}>
                      <Form.Check
                        inline
                        key="check_code"
                        id="check_code"
                        checked={state.isDisplayCodeSector}
                        type="switch"
                        label="コードテーブル"
                        onChange={() => $state({ ...state, isDisplayCodeSector: !state.isDisplayCodeSector })}
                      />
                      <Form.Check
                        inline
                        key="check_user"
                        id="check_user"
                        checked={state.isDisplayUserSector}
                        type="switch"
                        label="従業員情報テーブル"
                        onChange={() => $state({ ...state, isDisplayUserSector: !state.isDisplayUserSector })}
                      />
                    </Col>
                  </Row>
                  <Row className="--align-items-center mb-1">
                    <Col md={2}>テーブル名：</Col>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        id={`filter`}
                        className="mb-1"
                        value={state.keyword}
                        onChange={(e) => $state({ ...state, keyword: e.target.value })}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row className="mb-1">
                <Col>
                  {displaySectors.length > 0 ? (
                    <Table
                      col={[
                        { name: "テーブル名", width: "250px" },
                        { name: "コード/従業員情報", width: "150px" },
                        { name: "アクション", width: "120px" },
                        { name: "概要", width: "300px" },
                      ]}
                      row={displaySectors.map((s) => {
                        const hasRenameTarget = renameTargetSectors.some((_) => _.sector_id === s.key);
                        const hasOptionEditableColumns = optionEditableSectors.some((_) => _.sector_id === s.key);
                        const isCodeSector = s.key.startsWith("profile_m_");
                        return {
                          id: s.key,
                          data: [
                            <Form.Check type="checkbox" id={s.key}>
                              <Form.Check.Input
                                checked={s.active}
                                onChange={() => selectSector(s.key)}
                                disabled={!CHANGEABLE_SECTOR_IDS.includes(s.key) || isCodeSector}
                              />
                              <Form.Check.Label style={{ opacity: 1 }}>{s.label}</Form.Check.Label>
                            </Form.Check>,
                            isCodeSector ? "コード" : "従業員情報",
                            <>
                              <div>
                                <Link to={`/_/masters/fields/edit/${s.key}`}>項目の編集</Link>
                              </div>
                              {hasOptionEditableColumns && (
                                <div>
                                  <Link to={`/_/masters/fields/options/${s.key}`}>選択肢の編集</Link>
                                </div>
                              )}
                            </>,
                            <div className="Table__col">{s.description}</div>,
                          ],
                        };
                      })}
                    />
                  ) : (
                    <Alert variant="info">条件に一致するテーブルが存在しません</Alert>
                  )}
                </Col>
              </Row>
              <Button className="mb-3" onClick={() => $state({ ...state, activeModal: "before_save" })}>
                保存
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalDialog
        show={state.activeModal === "before_save"}
        onConfirm={() => {
          const sectorStatus = state.sectors
            .filter((s) => s.key.startsWith(USER_TABLE_PREFIX))
            .reduce((prev: { [key: string]: boolean }, item) => {
              prev[item.key] = item.active;
              return prev;
            }, {});
          dispatch(
            putSectorStatus({
              SectorStatusId: SectorStatusId,
              sectorStatus,
            })
          );
          $state({ ...state, activeModal: "" });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"保存します。よろしいですか？"}
      />
    </Container>
  );
}

export default MasterDataList;
