import { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { testResponse } from "../../app/util";
import { useAppSelector, useAppDispatch, callOnStoreInitialized, ASSET_PATH } from "../../app/store";
import { getIdToken, selectTokenState } from "../../features/login/idTokenSlice";
import { getSectors, initSectorSettingStatus, initSectorStatus } from "../../features/client/clientSlice";
import { Sector } from "../../features/client/clientValues";
import { getUserByIdToken, getMyPolicy, selectUserState, getCompanyChoices } from "../../features/login/userSlice";
import { getParentThreadComments } from "../../features/client/threadSlice";
import { Alert, Container, Row, Button, Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getSelfAccount } from "../profile/profileSlice";
import { decodedToken } from "./idTokenSlice";

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { idTokenErrorMessage } = useAppSelector(selectTokenState);
  const userState = useAppSelector(selectUserState);
  const [state, $state] = useState({
    companyCode: "",
    loginCode: "",
    password: "",
    errorMessage: "",
    isProcessing: false,
  });

  const isReady = useCallback(() => {
    return !!(state.companyCode && state.loginCode && state.password);
  }, [state.companyCode, state.loginCode, state.password]);

  const login = useCallback(async () => {
    if (state.isProcessing) return;
    $state({ ...state, errorMessage: "", isProcessing: true });
    const reject = () => $state({ ...state, errorMessage: "ログインできませんでした。", isProcessing: false });
    const result = testResponse(
      await dispatch(
        getIdToken({
          companyCode: state.companyCode,
          loginCode: state.loginCode,
          password: state.password,
        })
      )
    );
    if (!result) return reject();

    localStorage.setItem("jwt", result.payload.idToken);
    localStorage.setItem(`refresh_token.${state.companyCode}`, result.payload.refreshToken);
    const user = testResponse(await dispatch(getUserByIdToken(result.payload.idToken)));
    if (!user) return reject();
    else if (user.payload.user.profile_roles.length === 0) {
      // アセスメントしか利用できないユーザーの場合アセスメントのダッシュボードへ
      window.location.href = "/assess/dashboard";
      return;
    }

    await Promise.all([
      dispatch(getMyPolicy()),
      dispatch(getSelfAccount({ account_id: user.payload.user.id })),
      dispatch(initSectorStatus()),
      dispatch(getCompanyChoices()),
      dispatch(getParentThreadComments()),
    ])
      .then((results) => {
        const myPolicy = results[0].payload as {
          policies: any;
        };
        new Promise((resolve, reject) => {
          dispatch(getSectors())
            .then((result: any) => {
              const sectors = result.payload as Sector[];
              if (sectors?.length > 0 && myPolicy.policies.data_loader?.includes("POST")) {
                dispatch(initSectorSettingStatus({ sectors: sectors }))
                  .then(resolve)
                  .catch(reject);
              } else {
                resolve(true);
              }
            })
            .catch(reject);
        });
      })
      .finally(() => {
        callOnStoreInitialized();
        let nextPath = "/_/dashboard/";
        if (localStorage.getItem("returnTo")) {
          nextPath = localStorage.getItem("returnTo") ?? "";
          localStorage.removeItem("returnTo");
        }
        navigate(nextPath);
      });
  }, [dispatch, state, navigate]);

  const errorMessage = useMemo(() => {
    if (state.isProcessing) return "";
    return idTokenErrorMessage || state.errorMessage;
  }, [state.isProcessing, idTokenErrorMessage, state.errorMessage]);
  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && isReady() && !state.isProcessing) login();
    };

    window.addEventListener("keydown", keydown);
    return () => {
      window.removeEventListener("keydown", keydown);
    };
  }, [state, userState, isReady, login]); // [] に渡さないと、次回関数コンポーネントが実行されたとき古いほうが参照されてしまう。

  useEffect(() => {
    const message = localStorage.getItem("excluded_message");
    if (message) {
      localStorage.removeItem("excluded_message");
      $state({ ...state, errorMessage: message });
    }
  }, []);

  return (
    <div className="Centered">
      <div className="Centered__content">
        <figure className="Centered__logo">
          <img src={`${ASSET_PATH}/login-logo-common.png`} alt="SHRPA" />
        </figure>
        <Container fluid="md" className="Grouping px-4 --bg-white-transparent">
          <Row>
            <Form>
              <Form.Group className="mb-3" controlId="companyCode">
                <Form.Control
                  value={state.companyCode}
                  onChange={(e) => $state({ ...state, companyCode: e.target.value })}
                  type="text"
                  placeholder="企業コード"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="loginCode">
                <Form.Control
                  value={state.loginCode}
                  onChange={(e) => $state({ ...state, loginCode: e.target.value })}
                  type="text"
                  autoComplete="username"
                  placeholder="ログインID"
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Control
                  value={state.password}
                  onChange={(e) => $state({ ...state, password: e.target.value })}
                  type="password"
                  autoComplete="current-password"
                  placeholder="パスワード"
                />
              </Form.Group>

              {errorMessage && (
                <Alert variant="danger" className="my-3">
                  {errorMessage}
                </Alert>
              )}

              <div className="d-grid gap-2">
                <Button className="mt-4" variant="primary" disabled={!isReady() || state.isProcessing} onClick={login}>
                  {state.isProcessing && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  )}
                  <span className="pl-2">ログイン</span>
                </Button>
                <Link to="/_/init" className="Anchor-link__item--label">
                  パスワードを忘れた場合
                </Link>
              </div>
            </Form>
          </Row>
        </Container>
        <div className="Centered__info">
          <Alert variant="info">
            ＊メンテナンスのお知らせ＊
            <br />
            月に数回、下記時間帯にメンテナンスを実施する場合があります。
            <br />
            メンテナンス中は、一時接続が切れたりログインできなくなる場合がございます。
            その場合は、しばらく時間を置いてから再度アクセスしていただくか、
            オンライン試験の場合はできる限りこの時間帯を避けてご利用ください。
            <br />
            ご不便をおかけいたしますが、何卒ご理解とご協力をお願い申し上げます。
            <br />
            <br />
            ・土曜、日曜、月曜　午前2:00～6:00 JST（日本標準時）
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default Login;
