import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, Form, Badge, OverlayTrigger, Tooltip, Modal, Card } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import ModalDialog from "../../component/ModalDialog";
import {
  commitApplicationStatus,
  copyApplicationConfig,
  deleteApplicationConfig,
  getApplicationStatus,
  getApplyTemplateSummaries,
  getEditingApplicationConfig,
  getNotificationTarget,
  commitNotificationTarget,
  selectApplyState,
  getApplicantConfig,
  commitApplicantConfig,
} from "./applySlice";
import classNames from "classnames";
import MultipleMemberSelector from "../profile/MultipleMemberSelector";
import MultiSelectItem from "../profile/MultiSelectItem";
import { getAllTerms } from "../../app/translate";
import SectionSelector from "../section/SectionSelector";
function ApplicationStatus() {
  const dispatch = useAppDispatch();
  const TERMS = getAllTerms();
  const { templateSummaries, notificationTarget, applicantConfig } = useAppSelector(selectApplyState);
  const [state, $state] = useState({
    changedStatus: {} as { [applicationType: string]: boolean },
    activeModal: "",
    targetApplicationType: "",
  });

  const [notificationTargetModal, $notificationTargetModal] = useState({
    isOpen: false,
    applicationTypes: [] as string[],
    selectedAccountIds: [] as number[],
    mode: "single" as "single" | "multiple",
  });

  const [applicantConfigModal, $applicantConfigModal] = useState({
    isOpen: false,
    applicationType: "",
    type: "",
    sections: [] as string[],
  });

  useEffect(() => {
    dispatch(getApplicationStatus());
    dispatch(getApplyTemplateSummaries());
    dispatch(getNotificationTarget());
    dispatch(getApplicantConfig());
  }, []);

  const filteredSummaries = useMemo(() => {
    return templateSummaries
      .filter((_) => _.template_status !== "obsolete")
      .map((summary) => {
        const _notificationTarget = notificationTarget[summary.application_type] ?? [];
        return { ...summary, notificationTarget: _notificationTarget };
      });
  }, [templateSummaries, notificationTarget]);

  const closeNotificationTargetModal = () => {
    $notificationTargetModal({
      ...notificationTargetModal,
      isOpen: false,
      applicationTypes: [],
      selectedAccountIds: [],
    });
  };

  const closeApplicantConfigModal = () => {
    $applicantConfigModal({
      isOpen: false,
      applicationType: "",
      type: "",
      sections: [],
    });
  };

  // 完了時通知先の一括設定で選択可能な申請書種別
  const selectableApplicationTypes = useMemo(() => {
    if (notificationTargetModal.mode !== "multiple" || !notificationTargetModal.isOpen) return [];
    return filteredSummaries
      .filter(({ application_type }) => application_type !== "application_template")
      .map(({ application_type, name }) => {
        return {
          label: name,
          value: application_type,
          checked: notificationTargetModal.applicationTypes.includes(application_type),
        };
      });
  }, [filteredSummaries, notificationTargetModal.applicationTypes, notificationTargetModal.isOpen]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <p>
            使用する申請書種別を設定してください。
            <br />
            申請書設定のコピーをする場合は「コピー」を押下してください。リリース済の最新設定がコピーされます。
            <br />
            注釈表示・追加項目・承認フローの設定は「編集」を押下してください。
          </p>
          <Table
            col={[
              { name: "申請書種別", width: "15%" },
              { name: "ステータス", width: "10%" },
              { name: "申請権限", width: "13%" },
              { name: "アクション", width: "15%" },
              {
                name: (
                  <>
                    完了時通知先
                    <Button
                      size="sm"
                      className="ms-1"
                      variant="outline-secondary"
                      onClick={() => {
                        $notificationTargetModal({ ...notificationTargetModal, mode: "multiple", isOpen: true });
                      }}
                    >
                      一括設定
                    </Button>
                  </>
                ),
                width: "13%",
              },
              { name: "説明", width: "34%" },
            ]}
            row={filteredSummaries.map(
              ({
                is_active,
                application_type,
                name,
                version,
                description,
                inactive_copy_targets,
                notificationTarget,
              }) => {
                return {
                  id: application_type,
                  data: [
                    <Form.Check type="checkbox" id={application_type}>
                      {application_type !== "application_template" && (
                        <Form.Check.Input
                          checked={!!(state.changedStatus[application_type] ?? is_active)}
                          onChange={(e) => {
                            const next = { ...state.changedStatus, [application_type]: e.target.checked };
                            $state({ ...state, changedStatus: next });
                          }}
                          disabled={version === null}
                        />
                      )}
                      <Form.Check.Label>{name}</Form.Check.Label>
                    </Form.Check>,
                    application_type !== "application_template" && (
                      <>
                        <span
                          className={classNames({
                            "Badge--ok": is_active,
                            "Badge--waiting": !is_active,
                          })}
                        >
                          {is_active ? "使用中" : "不使用"}
                        </span>
                        {is_active && inactive_copy_targets.length > 0 && (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 50, hide: 50 }}
                            overlay={(props) => (
                              <Tooltip id={`tooltip_${application_type}`} {...props}>
                                データ反映先に管理対象外のテーブルが含まれています
                              </Tooltip>
                            )}
                          >
                            <Badge pill bg="warning" text="dark" className="mx-1">
                              !
                            </Badge>
                          </OverlayTrigger>
                        )}
                      </>
                    ),
                    application_type !== "application_template" && (
                      <Button
                        variant="link"
                        onClick={() =>
                          $applicantConfigModal({
                            isOpen: true,
                            applicationType: application_type,
                            type: applicantConfig?.[application_type]?.type,
                            sections: applicantConfig?.[application_type]?.sections ?? [],
                          })
                        }
                      >
                        {TERMS[`APPLICANT_CONFIG_TYPE_${applicantConfig?.[application_type]?.type ?? "self"}`]}
                      </Button>
                    ),
                    <>
                      {version === null ? (
                        <Button
                          variant="link"
                          className="ps-1"
                          onClick={() =>
                            $state({ ...state, activeModal: "before_delete", targetApplicationType: application_type })
                          }
                        >
                          削除
                        </Button>
                      ) : (
                        <Button
                          variant="link"
                          className="ps-1"
                          onClick={() =>
                            $state({ ...state, activeModal: "before_copy", targetApplicationType: application_type })
                          }
                        >
                          コピー
                        </Button>
                      )}
                      {application_type !== "application_template" && (
                        <Link className="ps-1" to={`/_/masters/application/${application_type}`}>
                          編集
                        </Link>
                      )}
                    </>,
                    application_type !== "application_template" ? (
                      <Button
                        variant="link"
                        onClick={() =>
                          $notificationTargetModal({
                            isOpen: true,
                            applicationTypes: [application_type],
                            selectedAccountIds: notificationTarget,
                            mode: "single",
                          })
                        }
                      >
                        {notificationTarget.length}件
                      </Button>
                    ) : null,
                    <div>{description}</div>,
                  ],
                };
              }
            )}
          />
          <Button className="mb-3" onClick={() => $state({ ...state, activeModal: "before_save" })}>
            保存
          </Button>
        </Col>
      </Row>
      <ModalDialog
        show={state.activeModal === "before_save"}
        onConfirm={() => {
          dispatch(commitApplicationStatus(state.changedStatus)).then(() => {
            dispatch(getApplyTemplateSummaries());
          });
          $state({ ...state, activeModal: "", changedStatus: {} });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"保存します。よろしいですか？"}
      />
      <ModalDialog
        show={state.activeModal === "before_copy"}
        onConfirm={() => {
          dispatch(copyApplicationConfig({ application_type: state.targetApplicationType })).then(() => {
            dispatch(getApplyTemplateSummaries());
          });
          $state({ ...state, activeModal: "", targetApplicationType: "" });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"最新のリリース済バージョンの内容をコピーします。よろしいですか？"}
      />
      <ModalDialog
        show={state.activeModal === "before_delete"}
        onConfirm={() => {
          dispatch(getEditingApplicationConfig({ application_type: state.targetApplicationType })).then((res) => {
            dispatch(deleteApplicationConfig({ id: res.payload.id })).then(() => {
              dispatch(getApplyTemplateSummaries());
            });
            $state({ ...state, activeModal: "", targetApplicationType: "" });
          });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"コピーした設定を削除します。よろしいですか？"}
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
      <ModalDialog
        show={state.activeModal === "before_save_notification_target"}
        onConfirm={async () => {
          const { applicationTypes, selectedAccountIds } = notificationTargetModal;
          await dispatch(
            commitNotificationTarget(
              applicationTypes.reduce((prev, current) => ({ ...prev, [current]: selectedAccountIds }), {})
            )
          );
          closeNotificationTargetModal();
          $state({ ...state, activeModal: "" });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"完了時通知先を保存します。よろしいですか？"}
      />
      <ModalDialog
        show={state.activeModal === "before_save_applicant_config"}
        onConfirm={async () => {
          const { applicationType, type, sections } = applicantConfigModal;
          await dispatch(
            commitApplicantConfig({ [applicationType]: { type, sections: type === "self" ? undefined : sections } })
          );
          closeApplicantConfigModal();
          $state({ ...state, activeModal: "" });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"設定します。よろしいですか？"}
      />
      <Modal show={notificationTargetModal.isOpen} onHide={closeNotificationTargetModal} size="lg" centered>
        <Modal.Body>
          <div className="--bold">通知先</div>
          <div className="--font-s">※申請書が完了時に通知されます。申請書の権限がなければ閲覧はできません。</div>
          {notificationTargetModal.mode === "multiple" && (
            <div className="--font-s">※現在の設定がある場合も、上書きされます。</div>
          )}
          <MultipleMemberSelector
            selectedAccountIds={notificationTargetModal.selectedAccountIds}
            onChange={(selectedAccountIds: number[]) =>
              $notificationTargetModal({ ...notificationTargetModal, selectedAccountIds })
            }
          />
          <div className="--bold mt-2">申請書種別</div>
          {notificationTargetModal.mode === "multiple" ? (
            <Card>
              <Card.Body>
                <MultiSelectItem
                  choices={selectableApplicationTypes}
                  onChange={(selected) =>
                    $notificationTargetModal({ ...notificationTargetModal, applicationTypes: selected })
                  }
                />
                {notificationTargetModal.applicationTypes.length === 0 && (
                  <span className="--font-s --text-annotation">一つ以上申請書種別を選択してください</span>
                )}
              </Card.Body>
            </Card>
          ) : (
            filteredSummaries.find(
              ({ application_type }) => application_type === notificationTargetModal.applicationTypes[0]
            )?.name
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeNotificationTargetModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            disabled={notificationTargetModal.applicationTypes.length === 0}
            onClick={() => $state({ ...state, activeModal: "before_save_notification_target" })}
          >
            設定
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={applicantConfigModal.isOpen} onHide={closeApplicantConfigModal} size="lg" centered>
        <Modal.Body>
          <div className="--bold mt-2">申請書種別</div>
          {
            filteredSummaries.find(({ application_type }) => application_type === applicantConfigModal.applicationType)
              ?.name
          }
          <div className="--bold mt-2 --required-label">申請権限</div>
          <Form.Select
            value={applicantConfigModal.type}
            onChange={(e) => $applicantConfigModal({ ...applicantConfigModal, type: e.target.value })}
          >
            {["self", "representative", "all"].map((type) => (
              <option key={type} value={type}>
                {TERMS[`APPLICANT_CONFIG_TYPE_${type}`]}
              </option>
            ))}
          </Form.Select>
          {["representative", "all"].includes(applicantConfigModal.type) && (
            <>
              <div className="--bold mt-2 --required-label">代理申請可能な部署</div>
              <SectionSelector
                selectedSectionCodes={applicantConfigModal.sections}
                onChange={(sections) => {
                  $applicantConfigModal({ ...applicantConfigModal, sections });
                }}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeApplicantConfigModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            disabled={
              applicantConfigModal.type === "" ||
              (["representative", "all"].includes(applicantConfigModal.type) &&
                applicantConfigModal.sections.length === 0)
            }
            onClick={() => $state({ ...state, activeModal: "before_save_applicant_config" })}
          >
            設定
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ApplicationStatus;
