import { useState, useMemo, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import { Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../../component/Sidebar";
import { selectUserRootRoles, PolicyMap, selectUserState } from "../login/userSlice";
import FileProjectAdminList from "./FileProjectAdminList";
import ReportListView from "../report/ReportListView";
import FileProjectAdminDetail from "./FileProjectAdminDetail";
import FileProjectCreate from "./FileProjectCreate";

type TAB = {
  label: string;
  eventKey: string;
  isAvailable: (policies: PolicyMap, roles: string[]) => boolean;
};

const TABS: TAB[] = [
  {
    label: "ファイル共有",
    eventKey: "file_admin",
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("file_project_admin_manager"))
        ?.some((api) => policies[api]?.includes("POST"));
    },
  },
  {
    label: "システム帳票",
    eventKey: "reports",
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("report_admin_manager"))
        ?.some((api) => policies[api]?.includes("POST"));
    },
  },
];

const getFileAdminPageType = (pathname: string) => {
  if (pathname.indexOf("/_/files/file_admin/create") === 0) return "create";
  else if (pathname.match(/\/_\/files\/file_admin\/.+/)) return "detail";
  else if (pathname.indexOf("/_/files/file_admin") === 0) return "list";
  else return "";
};

function App() {
  const location = useLocation();
  const { mode } = useParams();
  const { policies } = useAppSelector(selectUserState);
  const roles = useAppSelector(selectUserRootRoles);
  const tabPanes = useMemo(() => {
    const tabPanes = TABS.filter((tab) => tab.isAvailable(policies, roles));
    return tabPanes;
  }, [roles, policies]);

  const [state, $state] = useState({
    fileAdminPageType: "list",
    activeTabKey: (() => {
      switch (mode) {
        case "file_admin":
        case "reports":
          return mode;
        default:
          return tabPanes?.[0]?.eventKey;
      }
    })(),
  });

  useEffect(() => {
    $state({ ...state, fileAdminPageType: getFileAdminPageType(location.pathname) });
  }, [location]);

  const tabContent = useMemo(() => {
    if (state.activeTabKey === "file_admin") {
      if (state.fileAdminPageType === "list") return <FileProjectAdminList />;
      if (state.fileAdminPageType === "detail") return <FileProjectAdminDetail />;
      if (state.fileAdminPageType === "create") return <FileProjectCreate />;
      return <FileProjectAdminList />;
    } else if (state.activeTabKey === "reports") return <ReportListView />;
    else return <></>;
  }, [state]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"file"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">ファイル管理</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Tab.Container
            onSelect={(key: string | null) => {
              let next = { ...state };
              if (key) {
                next = { ...next, activeTabKey: key, fileAdminPageType: "list" };
                window.history.replaceState({}, "", `/_/files/${key}`);
              }
              $state(next);
            }}
            activeKey={state.activeTabKey}
          >
            <Nav variant="tabs">
              {tabPanes.map((t) => (
                <Nav.Item key={t.eventKey}>
                  <Nav.Link eventKey={t.eventKey}>{t.label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content className="py-2">{tabContent}</Tab.Content>
          </Tab.Container>
        </main>
      </div>
    </div>
  );
}

export default App;
