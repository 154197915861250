import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch } from "../../app/store";
import { downloadUploadFormat, uploadMyNumber } from "../myNumber/myNumberSlice";
import Sidebar from "../../component/Sidebar";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import { useNavigate } from "react-router-dom";
import { showToast } from "../notification/notificationSlice";
import { getAllTerms } from "../../app/translate";

function MyNumberUpload() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const TERMS = getAllTerms();
  const onFileLoad = async (decodedFileData: DecodedFileData) => {
    dispatch(
      uploadMyNumber({
        file: decodedFileData.dataURI,
        name: decodedFileData.name,
      })
    );
    dispatch(
      showToast({
        id: `toast-${Date.now()}`,
        content: "ファイルをアップロードしました。完了後、通知されます。",
      })
    );
    navigate("/_/my_number/");
  };

  const downloadFormat = (type: string) => {
    dispatch(
      downloadUploadFormat({
        type,
        terms: {
          login_code: TERMS.MY_NUMBER_LOGIN_CODE,
          name: TERMS.MY_NUMBER_NAME,
          employee_name: TERMS.MY_NUMBER_EMPLOYEE_NAME,
          value: TERMS.MY_NUMBER_VALUE,
          type: TERMS.MY_NUMBER_TYPE,
          dependent_serial: TERMS.MY_NUMBER_DEPENDENT_SERIAL,
          status: TERMS.MY_NUMBER_STATUS,
          MY_NUMBER_TYPE_self: TERMS.MY_NUMBER_TYPE_self,
          MY_NUMBER_TYPE_spouse: TERMS.MY_NUMBER_TYPE_spouse,
          MY_NUMBER_TYPE_dependent: TERMS.MY_NUMBER_TYPE_dependent,
        },
      })
    );
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">マイナンバー - 一括アップロード</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <div>
              <Row>
                <Col>
                  <h3 className="Headline--section-sub mb-2">アップロード</h3>
                  <Uploader
                    onFileLoad={onFileLoad}
                    accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <h3 className="Headline--section-sub mb-2">登録用ファイルのダウンロード</h3>
                  <div className="mb-2">アップロード可能な形式のファイルをダウンロードできます。</div>
                  <div className="mt-2">
                    <Button onClick={() => downloadFormat("example")} variant="outline-primary">
                      記入例のみのファイルをダウンロード
                    </Button>
                  </div>
                  <div className="mt-2">
                    <Button onClick={() => downloadFormat("data")} variant="outline-primary">
                      未登録分の一括登録用のファイルをダウンロード
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default MyNumberUpload;
