import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { call } from "../../app/api";
import { store, RootState } from "../../app/store";
import dayjs from "dayjs";
import { Section, Position, SectionTreeNode } from "./sectionValues";

const SLICE_NAME = "section";

export const SECTION_SEARCH_LIMIT = 20;

interface SectionState {
  sections: Section[];
  filteredSections: Section[];
  parentCodeSections: { [parentCode: string]: Section[] };
  selectedSection: Section | null;
  positions: Position[];
}

/*
  state の初期状態
*/
const initialState: SectionState = {
  sections: [],
  filteredSections: [],
  parentCodeSections: {},
  selectedSection: null,
  positions: [],
};

const _toSections = (data: any[]) => {
  return data.map((r: any) => {
    return {
      id: r.id,
      name: r.section_name,
      sectionCode: r.section_code,
      parentSectionCode: r.parent_section_code,
      valid_from: r.valid_from,
      valid_to: r.valid_to,
      order: r.order,
    } as Section;
  });
};

export const selectSection = createAsyncThunk(SLICE_NAME + "/selectSection", async ({ id }: { id: number }) => {
  return store.getState().section.sections.find((s: Section) => s.id === id) ?? null;
});

export const getSections = createAsyncThunk(
  SLICE_NAME + "/getSections",
  async ({ baseDate, sectionCodes }: { baseDate: dayjs.Dayjs; sectionCodes?: string[] }): Promise<Section[]> => {
    const res = await call(
      "get",
      "master_data_manager/section"
    )({
      base_date: baseDate.format("YYYY-MM-DD"),
      section_code__in: sectionCodes,
      sort_by: ["order"],
    });

    return _toSections(res.data.result);
  }
);

export const filterSections = createAsyncThunk(
  SLICE_NAME + "/filterSections",
  async ({ baseDate, limit, keyword }: { baseDate: dayjs.Dayjs; limit: number; keyword: string }) => {
    const params = {
      base_date: baseDate.format("YYYY-MM-DD"),
      limit,
      section_name__contain: keyword,
      sort_by: ["order"],
    } as { [key: string]: any };
    const res = await call("get", "master_data_manager/section")(params);
    return _toSections(res.data.result);
  }
);
export const getParentCodeSections = createAsyncThunk(
  SLICE_NAME + "/getParentCodeSections",
  async ({ baseDate, parentCodes }: { baseDate: dayjs.Dayjs; parentCodes?: string[] }) => {
    const params = {
      base_date: baseDate.format("YYYY-MM-DD"),
      parent_section_code__in: parentCodes,
    } as { [key: string]: any };
    if (!parentCodes) {
      params["parent_section_code__is_null"] = true;
    }
    const res = await call("get", "master_data_manager/section")(params);
    const sections = res.data.result.reduce((prev: { [parentCode: string]: Section[] }, current: any) => {
      const parentSectionCode = current.parent_section_code === null ? "" : current.parent_section_code;
      return {
        ...prev,
        [parentSectionCode]: [
          ...(prev?.[parentSectionCode] ?? []),
          {
            id: current.id,
            name: current.section_name,
            sectionCode: current.section_code,
            parentSectionCode: current.parent_section_code,
            valid_from: current.valid_from,
            valid_to: current.valid_to,
            order: current.order,
          } as Section,
        ],
      };
    }, {} as { [parentCode: string]: Section[] });
    return sections;
  }
);

export const getPositions = createAsyncThunk(
  SLICE_NAME + "/getPositions",
  async ({ baseDate }: { baseDate: dayjs.Dayjs }): Promise<Position[]> => {
    const res = await call(
      "get",
      "master_data_manager/position"
    )({
      base_date: baseDate.format("YYYY-MM-DD"),
    });
    const positions: Position[] = res.data.result.map((r: any) => {
      return {
        id: r.id,
        positionCode: r.position_code,
        positionName: r.position_name,
        valid_from: r.valid_from,
        valid_to: r.valid_to,
        order: r.order,
      } as Position;
    });
    return positions;
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    unselectSection: (state) => {
      state.selectedSection = null;
      return state;
    },
    clearFilteredSections: (state) => {
      state.filteredSections = [];
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectSection.fulfilled, (state, action) => {
      state.selectedSection = action.payload;
    });
    builder.addCase(getSections.fulfilled, (state, action) => {
      state.sections = action.payload;
    });
    builder.addCase(filterSections.fulfilled, (state, action) => {
      state.filteredSections = action.payload;
    });
    builder.addCase(getParentCodeSections.fulfilled, (state, action) => {
      state.parentCodeSections = { ...state.parentCodeSections, ...action.payload };
    });
    builder.addCase(getPositions.fulfilled, (state, action) => {
      state.positions = action.payload;
    });
  },
});

export const { unselectSection, clearFilteredSections } = slice.actions;

export const selectSectionState = (state: RootState) => {
  return state.section as SectionState;
};

export const selectSectionTree = (state: RootState) => {
  /*
    部署を親子構造に変換する
  */
  let nodes: SectionTreeNode[] = state.section.sections.map((s: Section) => {
    return {
      id: s.id,
      name: s.name,
      sectionCode: s.sectionCode,
      parentSectionCode: s.parentSectionCode,
      children: [],
      count: [],
      order: s.order,
    } as SectionTreeNode;
  });
  nodes.forEach((n) => {
    if (n.parentSectionCode) {
      const parent = nodes.find((node) => node.sectionCode === n.parentSectionCode);
      if (parent) {
        parent.children.push(n);
      }
    }
  });
  nodes.forEach((n) => {
    n.children.sort((a, b) => a.order - b.order);
  });
  let result = nodes
    .filter((n) => !n.parentSectionCode)
    .map((n) => {
      return {
        id: n.id,
        name: n.name,
        sectionCode: n.sectionCode,
        children: n.children,
        count: n.count,
        order: n.order,
      };
    }) as SectionTreeNode[];
  return result;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
