import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import { ProfileState } from "../profile/profileSlice";
import { call } from "../../app/api";
import { DecodedFileData } from "../../component/Uploader";
import download from "../../app/download";
import { handleError } from "../error/fatalErrorSlice";
import { MyNumber, MyNumberFiles, MyNumberLog, MyNumberView } from "./myNumberValues";

const SLICE_NAME = "myNumber";

interface MyNumberState {
  processing: boolean;
  myNumberViews: MyNumberView[];
  selectedMyNumber: MyNumber | null;
  concernedAccountIds: number[];
  logs: MyNumberLog[];
  textForEmployee: string;
  textForFamily: string;
  holdImageFiles: boolean;
  notificationTemplate: string;
  pendingYears: number;
  pendingMonths: number;
  downloadLogs: MyNumberLog[];
  myNumberValue: string;
  myNumberFiles: MyNumberFiles;
  statusMessages: { [key: string]: string };
}

const initialState: MyNumberState = {
  processing: false,
  myNumberViews: [],
  selectedMyNumber: null,
  concernedAccountIds: [],
  logs: [],
  textForEmployee: "",
  textForFamily: "",
  holdImageFiles: false,
  notificationTemplate: "",
  pendingYears: 0,
  pendingMonths: 0,
  downloadLogs: [],
  myNumberValue: "",
  myNumberFiles: {},
  statusMessages: {},
};

export const getMyNumberViews = createAsyncThunk<
  { myNumberViews: MyNumberView[]; concernedAccountIds: number[] },
  { accountId?: number } | void
>(SLICE_NAME + "/getMyNumberViews", async (options) => {
  const myNumberRes = await call("get", "my_number_manager/list")({ account_id: options?.accountId });
  const myNumberViews = myNumberRes.data.result.map((res: any) => {
    if (!res.id) return { ...res, id: `_temp_${res.account_id}_${res.type}_${res.dependent_serial}` };
    return res;
  }) as MyNumberView[];
  const concernedAccountIds = Array.from(new Set([...myNumberViews.map((v) => v.account_id)]));
  return { myNumberViews, concernedAccountIds };
});

export const selectMyNumber = createAsyncThunk(SLICE_NAME + "/selectMyNumber", async ({ id }: { id: string }) => {
  const res = await call("get", "my_number_manager/my_number")({ id });
  const myNumber = res.data.result?.[0] as MyNumber;
  const { number_file_id, identification_file_1_id, identification_file_2_id } = myNumber;
  const myNumberFiles = await Promise.all(
    Object.entries({ number_file_id, identification_file_1_id, identification_file_2_id }).map(
      async ([columnName, id]) => {
        const result = await call("get", "my_number_manager/file")({ id });
        const { file_name, file, mime } = result.data.result;
        return {
          [columnName]: {
            name: file_name,
            dataURI: file,
            type: mime,
            dataURIprefix: `data:${mime};base64`,
          },
        };
      }
    )
  ).then((result) => {
    // すべての非同期処理の結果を一度に処理
    return result.reduce((prev, current) => ({ ...prev, ...current }), {});
  });
  return { myNumber, myNumberFiles };
});

export const attachMyNumberFile = createAsyncThunk(
  SLICE_NAME + "/attachMyNumberFile",
  async ({ fileId, decodedFileData }: { fileId: string; decodedFileData: DecodedFileData }) => {
    const { dataURI, name } = decodedFileData;
    await call("post", "my_number_manager/file")({ id: fileId, file: dataURI, name });
  }
);

export const deleteMyNumberFile = createAsyncThunk(
  SLICE_NAME + "/deleteMyNumberFile",
  async ({ fileId }: { fileId: string }) => {
    await call("delete", "my_number_manager/file")({ id: fileId });
  }
);

export const postMyNumber = createAsyncThunk(
  SLICE_NAME + "/postMyNumber",
  async (data: { accountId?: number; type: string; dependentSerial?: number }) => {
    const res = await call(
      "post",
      "my_number_manager/my_number"
    )({
      account_id: data.accountId,
      type: data.type,
      dependent_serial: data.dependentSerial,
    });
    return res.data.result[0];
  }
);

export const getMyNumber = createAsyncThunk(SLICE_NAME + "/getMyNumber", async ({ id }: { id: string }) => {
  const res = await call("get", "my_number_manager/decrypt")({ id });
  return res.data.result?.[0].value as string;
});

export const applyMyNumber = createAsyncThunk(
  SLICE_NAME + "/applyMyNumber",
  async ({ id, value, agreed_at }: { id: string; value: string; agreed_at?: number }) => {
    const res = await call("post", "my_number_manager/apply")({ id, value, agreed_at });
    return res.data.result[0];
  }
);
export const approveMyNumber = createAsyncThunk(SLICE_NAME + "/approveMyNumber", async ({ id }: { id: string }) => {
  const res = await call("post", "my_number_manager/approve")({ id });
  return res.data.result[0];
});

export const rejectMyNumber = createAsyncThunk(
  SLICE_NAME + "/rejectMyNumber",
  async ({ id, reason }: { id: string; reason: string }) => {
    const res = await call("post", "my_number_manager/reject")({ id, reason });
    return res.data.result[0];
  }
);

export const registerMyNumber = createAsyncThunk(
  SLICE_NAME + "/registerMyNumber",
  async ({ id, value }: { id: string; value: string }) => {
    const res = await call("post", "my_number_manager/register")({ id, value });
    return res.data.result[0];
  }
);

export const setMyNumberDeletable = createAsyncThunk(
  SLICE_NAME + "/setMyNumberDeletable",
  async ({ ids }: { ids: string[] }) => {
    const res = await call("post", "my_number_manager/set_deletable")({ id__in: ids });
    return res.data.result[0];
  }
);

export const requestMyNumberApply = createAsyncThunk(
  SLICE_NAME + "/requestMyNumberApply",
  async ({ targets }: { targets: { account_id: number; type: string; dependent_serial?: number }[] }) => {
    await call("post", "my_number_manager/request_apply")({ targets });
  }
);

export const getMyNumberSettings = createAsyncThunk(SLICE_NAME + "/getMyNumberSettings", async () => {
  const res = await call("get", "my_number_manager/config")();
  return res.data.result[0];
});

export const getMyNumberDownloadLogs = createAsyncThunk(SLICE_NAME + "/getMyNumberDownloadLogs", async () => {
  const res = await call(
    "get",
    "operation_log_manager/operation_log"
  )({
    service_id: 10,
    action_type: "download",
    sort_by: '[{ "time": -1 }]',
  });
  return res.data.result;
});

export const getMyNumberLogs = createAsyncThunk<MyNumberLog[], { myNumberId: string }>(
  SLICE_NAME + "/getMyNumberLogs",
  async ({ myNumberId }) => {
    const res = await call(
      "get",
      "operation_log_manager/operation_log"
    )({
      service_id: 10,
      objects: myNumberId,
      sort_by: '[{ "time": -1 }]',
    });
    return res.data.result;
  }
);

export const getStatusMessages = createAsyncThunk(
  SLICE_NAME + "/getStatusMessages",
  async ({ myNumberIds }: { myNumberIds: string[] }) => {
    return await Promise.all(
      myNumberIds.map(async (myNumberId) => {
        const res = await call(
          "get",
          "operation_log_manager/operation_log"
        )({
          service_id: 10,
          objects: myNumberId,
          sort_by: '[{ "time": -1 }]',
          limit: 1,
        });
        return { [myNumberId]: res.data.result[0] };
      })
    ).then((result) => {
      // すべての非同期処理の結果を一度に処理
      return result.reduce((prev, res) => {
        Object.entries(res).forEach(([myNumberId, record]) => {
          prev[myNumberId] = record?.remarks;
        });
        return prev;
      }, {});
    });
  }
);

export const issueOneTimePassword = createAsyncThunk(SLICE_NAME + "/issueOneTimePassword", async () => {
  await call("post", "my_number_manager/one_time_password")();
});

export const downloadMyNumbers = createAsyncThunk(
  SLICE_NAME + "/downloadMyNumbers",
  async ({
    oneTimePassword,
    purpose,
    terms,
  }: {
    oneTimePassword: string;
    purpose: string;
    terms: { [key: string]: string };
  }) => {
    const res = await call("get", "my_number_admin_manager/download", { handleError: false })({
      one_time_password: oneTimePassword,
      purpose,
      terms,
    }).catch((e) => {
      const error = e?.response?.data?.errors[0]?.reason ?? "";
      if (error.startsWith("E004"))
        throw new Error("ダウンロードできませんでした。ワンタイムパスワードをご確認ください。");
      store.dispatch(handleError({ type: "error", message: "予期せぬエラーが発生しました。" }));
    });

    const { file, file_name, mime } = res.data.result;
    download({ file, fileName: file_name, mimeType: mime });
  }
);

export const uploadMyNumber = createAsyncThunk(
  SLICE_NAME + "/uploadMyNumber",
  async ({ file, name }: { file: string; name: string }) => {
    const next_process = {
      externalEffect: "_getMyNumbers",
      externalEffectUsed: false,
      payload: {},
    };
    await call("post", "my_number_manager/upload")({ file, name, next_process });
  }
);

export const downloadUploadFormat = createAsyncThunk(
  SLICE_NAME + "/downloadUploadFormat",
  async ({ terms, type }: { terms: { [key: string]: string }; type: string }) => {
    const res = await call("get", "my_number_manager/format_download")({ terms, type });
    const { file, file_name, mime } = res.data.result;
    download({ file, fileName: file_name, mimeType: mime });
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearMyNumberValue: (state) => {
      state.myNumberValue = "";
    },
    clearSelectedMyNumber: (state) => {
      state.selectedMyNumber = null;
      state.myNumberFiles = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyNumberViews.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberViews.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberViews.fulfilled, (state, action) => {
      state.processing = false;
      state.myNumberViews = action.payload.myNumberViews;
      state.concernedAccountIds = action.payload.concernedAccountIds;
    });
    builder.addCase(selectMyNumber.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(selectMyNumber.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(selectMyNumber.fulfilled, (state, action) => {
      state.processing = false;
      state.selectedMyNumber = action.payload.myNumber;
      state.myNumberFiles = action.payload.myNumberFiles;
    });
    builder.addCase(getMyNumber.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumber.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumber.fulfilled, (state, action) => {
      state.processing = false;
      state.myNumberValue = action.payload;
    });
    builder.addCase(getMyNumberLogs.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberLogs.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberLogs.fulfilled, (state, action) => {
      state.processing = false;
      state.logs = action.payload;
    });
    builder.addCase(getMyNumberSettings.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberSettings.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberSettings.fulfilled, (state, action) => {
      state.processing = false;
      state.textForEmployee = action.payload.text_for_employee;
      state.textForFamily = action.payload.text_for_family;
      state.pendingYears = action.payload.pending_years;
      state.pendingMonths = action.payload.pending_months;
      state.holdImageFiles = action.payload.hold_image_files;
      state.notificationTemplate = action.payload.notification_template;
    });
    builder.addCase(getMyNumberDownloadLogs.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberDownloadLogs.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberDownloadLogs.fulfilled, (state, action) => {
      state.processing = false;
      state.downloadLogs = action.payload;
    });
    builder.addCase(getStatusMessages.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getStatusMessages.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getStatusMessages.fulfilled, (state, action) => {
      state.processing = false;
      state.statusMessages = action.payload;
    });
    builder.addCase(attachMyNumberFile.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(attachMyNumberFile.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(attachMyNumberFile.fulfilled, (state, action) => {
      state.processing = false;
    });
    builder.addCase(deleteMyNumberFile.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(deleteMyNumberFile.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(deleteMyNumberFile.fulfilled, (state, action) => {
      state.processing = false;
    });
  },
});

export const { clearMyNumberValue, clearSelectedMyNumber } = slice.actions;

export const selectMyNumberState = (state: RootState) => {
  return state.myNumber as MyNumberState;
};

export const selectLogsWithAccount = (state: RootState) => {
  const { accounts } = state.profile as ProfileState;
  const { logs } = state.myNumber as MyNumberState;
  return logs.map((log) => {
    return {
      log,
      account: accounts.find((a) => a.id === log.subject),
    };
  });
};

export const selectDownloadLogsWithAccount = (state: RootState) => {
  const { accounts } = state.profile as ProfileState;
  const { downloadLogs } = state.myNumber as MyNumberState;
  return downloadLogs.map((log) => {
    return {
      ...log,
      account: accounts.find((a) => a.id === log.subject) ?? null,
    };
  });
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
