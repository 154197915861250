import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
  MailSetting,
  MailSettingDetail,
  SendMailCondition,
  SendMailTask,
  Project,
  Workgroup,
  Account,
  SearchCondition,
  MailHistory,
} from "./mailSettingValues";
import { call, callCollaborator } from "../../app/api";

const SLICE_NAME = "mailSetting";

interface MailSettingState {
  mailSettings: MailSetting[];
  selectedMailSetting: MailSettingDetail;
  sendMailTask: SendMailTask;
  projects: Project[];
  wholeProjects: Project[];
  workgroups: Workgroup[];
  accounts: Account[];
  projectLosted: boolean;
  mailHistories: MailHistory[];
}

const emptyMailSetting: MailSettingDetail = {
  id: "",
  eta: "",
  name: "",
  status: "",
  mail_template_id: "",
  from_name: "",
  from_address: "",
  reply_to: "",
  target: "",
  cc: [],
  bcc: [],
  conditions: [],
};

const initialState: MailSettingState = {
  mailSettings: [],
  selectedMailSetting: emptyMailSetting,
  sendMailTask: {
    count: 0,
    receivers: [],
  },
  projects: [],
  wholeProjects: [],
  workgroups: [],
  accounts: [],
  projectLosted: true,
  mailHistories: [],
};

export const getMailSettings = createAsyncThunk(
  SLICE_NAME + "/getMailSettings",
  async ({ conditions }: { conditions?: SearchCondition }) => {
    const params = { type: "sendMail" } as SearchCondition;
    if (conditions && conditions["keyword"]) {
      params.name__contain = conditions.keyword;
    }
    const res = await callCollaborator("get", "timethinver_api/task")(params);
    const mailSettings: MailSetting[] = res.data.results[0].result.map((m: any) => {
      return {
        id: m.id,
        eta: m.eta,
        name: m.name,
        status: m.status,
      } as MailSetting;
    });
    return { mailSettings };
  }
);

export const getMailSetting = createAsyncThunk(SLICE_NAME + "/getMailSetting", async ({ id }: { id: string }) => {
  const res = await callCollaborator("get", "timethinver_api/task")({ id });
  const result = res.data.results[0].result[0];
  const data = result.kwargs.data;
  const conditions = data.conditions.map((c: any) => ({
    ...c,
    remarks: c.remarks || "",
  }));
  const mailSetting: MailSettingDetail = {
    id: result.id,
    eta: result.eta,
    name: result.name,
    status: result.status,
    mail_template_id: data.mail_template_id,
    from_name: data.messenger_name,
    from_address: data.messenger,
    reply_to: data.reply_to,
    cc: data.cc,
    bcc: data.bcc,
    target: data.target,
    conditions,
  };
  return mailSetting;
});

export const deleteMailSettings = createAsyncThunk(
  SLICE_NAME + "/deleteMailSettings",
  async ({ ids }: { ids: string[] }) => {
    // バックエンド実装後、id__inでまとめて削除する処理に変更
    const options = ids.map((id) => {
      return { id };
    });
    await callCollaborator("delete", "timethinver_api/task")(options);
  }
);

export const updateMailSetting = createAsyncThunk(
  SLICE_NAME + "/updateMailSetting",
  async ({
    id,
    name,
    mailTemplateId,
    eta,
    fromName,
    fromAddress,
    replyTo,
    cc,
    bcc,
    target,
    conditions,
  }: {
    id: string;
    name: string;
    mailTemplateId: string;
    eta: string;
    fromName: string;
    fromAddress: string;
    replyTo: string;
    cc: string[];
    bcc: string[];
    target: string;
    conditions: SendMailCondition[];
  }) => {
    const res = await callCollaborator(
      "put",
      "timethinver_api/task"
    )({
      id,
      name,
      eta,
      kwargs: {
        api: "mail_manager",
        resource: "mail_task",
        method: "POST",
        data: {
          messenger: fromAddress,
          messenger_name: fromName,
          reply_to: replyTo,
          mail_template_id: mailTemplateId,
          cc,
          bcc,
          target,
          conditions,
        },
      },
    });
    const result = res.data.results[0].result[0];
    const data = result.kwargs.data;
    const mailSetting: MailSettingDetail = {
      id: result.id,
      eta: result.eta,
      name: result.name,
      status: result.status,
      mail_template_id: data.mail_template_id,
      from_name: data.messenger_name,
      from_address: data.messenger,
      reply_to: data.reply_to,
      cc: data.cc,
      bcc: data.bcc,
      target: data.target,
      conditions: data.conditions,
    };
    return mailSetting;
  }
);

export const registerMailSetting = createAsyncThunk(
  SLICE_NAME + "/registerMailSetting",
  async ({
    name,
    mailTemplateId,
    eta,
    fromName,
    fromAddress,
    replyTo,
    cc,
    bcc,
    target,
    conditions,
  }: {
    name: string;
    mailTemplateId: string;
    eta: string;
    fromName: string;
    fromAddress: string;
    replyTo: string;
    cc: string[];
    bcc: string[];
    target: string;
    conditions: SendMailCondition[];
  }) => {
    const res = await callCollaborator(
      "post",
      "timethinver_api/task"
    )({
      type: "sendMail",
      name,
      eta,
      kwargs: {
        api: "mail_manager",
        resource: "mail_task",
        method: "POST",
        data: {
          messenger: fromAddress,
          messenger_name: fromName,
          reply_to: replyTo,
          mail_template_id: mailTemplateId,
          cc,
          bcc,
          target,
          conditions,
        },
      },
    });
    const result = res.data.results[0].result[0];
    const data = result.kwargs.data;
    const mailSetting: MailSettingDetail = {
      id: result.id,
      eta: result.eta,
      name: result.name,
      status: result.status,
      mail_template_id: data.mail_template_id,
      from_name: data.messenger_name,
      from_address: data.messenger,
      reply_to: data.reply_to,
      cc: data.cc,
      bcc: data.bcc,
      target: data.target,
      conditions: data.conditions,
    };
    return mailSetting;
  }
);

export const getSendMailTask = createAsyncThunk(
  SLICE_NAME + "/getSendMailTask",
  async ({ target, conditions }: { target: string; conditions: SendMailCondition[] }) => {
    const res = await callCollaborator("get", "mail_manager/mail_task")({ target, conditions });
    const result = res.data.results[0].result[0];
    const receivers = result.receivers || [];
    const { user } = store.getState().user;
    const sendMailTask: SendMailTask = {
      count: result.count,
      receivers: receivers.map((r: any) => {
        return {
          id: r["account.id"],
          image: r["account.image"],
          is_active: r["account.is_active"],
          is_remind: r["account.is_remind"],
          language: r["account.language"],
          login_code: r["account.login_code"],
          mail_address: r["account.mail_address"],
          main_company_code: r["account.main_company_code"],
          name: r["account.name"],
          password: r["account.password"],
          account_id: r["account_id"],
          company_id: r["company_id"],
          is_billing: r["account.is_billing"],
          is_guest: r["account.main_company_code"] !== user.current_company.code,
        };
      }),
    };
    return sendMailTask;
  }
);

export const getProjects = createAsyncThunk(
  SLICE_NAME + "/getProjects",
  async ({ conditions }: { conditions?: SearchCondition }) => {
    const params = {} as SearchCondition;
    if (conditions && conditions["projectIds"]) {
      params.id__in = conditions["projectIds"];
    }
    const res = await callCollaborator("get", "project_manager/project")(params);
    const result = res.data.results[0].result;
    const projects: Project[] = result.map((p: any) => {
      return {
        name: p.project_name,
        project_id: p.id,
      };
    });
    return projects;
  }
);

export const getWholeProjects = createAsyncThunk(SLICE_NAME + "/getWholeProjects", async () => {
  const res = await callCollaborator("get", "project_manager/whole_project")();
  const result = res.data.results[0].result;
  const wholeProjects: Project[] = result.map((p: any) => {
    return {
      name: p.project_name,
      project_id: p.id,
      workflow_id: p.workflow.id,
      service_id: p.service_id,
    };
  });
  return wholeProjects;
});

export const getWorkgroups = createAsyncThunk(
  SLICE_NAME + "/getWorkgroups",
  async (conditions: SendMailCondition[]) => {
    const workgroups = await Promise.all(
      conditions.map(async (c: SendMailCondition) => {
        if (!c.project_id) {
          return [];
        }
        const res = await callCollaborator("get", "project_manager/whole_project")({ id: c.project_id });
        const result = res.data.results[0].result[0];
        return result.workflow.workgroups.map((w: any) => {
          return {
            name: w.workgroup_name,
            value: w.id,
          };
        });
      })
    );
    return workgroups;
  }
);

export const getAccounts = createAsyncThunk(
  SLICE_NAME + "/getAccounts",
  async ({ conditions }: { conditions?: SearchCondition }) => {
    const params = {} as SearchCondition;
    if (conditions && conditions["accountIds"]) {
      params.account_id__in = conditions["accountIds"];
    }
    if (conditions && conditions["keyword"]) {
      params.or = [
        {
          name__contain: conditions.keyword,
          login_code__contain: conditions.keyword,
          mail_address__contain: conditions.keyword,
        },
      ];
    }
    params.sort_by = ["account_id"];
    // 無効アカウント除外
    params.is_active = true;
    const res = await call("get", "account_manager/account_view")(params);
    const result = res.data.result;
    const { user } = store.getState().user;
    const accounts: Account[] = result.map((a: any) => {
      return {
        id: a.account_id,
        name: a.name,
        login_code: a.login_code,
        mail_address: a.mail_address,
        is_billing: !!a.is_billing,
        is_guest: a.main_company_code !== user.current_company.code,
      };
    });
    return accounts;
  }
);

export const getMailHistories = createAsyncThunk(
  SLICE_NAME + "/getMailHistories",
  async ({ id, conditions }: { id: string; conditions?: SearchCondition }) => {
    const params = { schedule_id: id } as SearchCondition;
    if (conditions && conditions["keyword"]) {
      params.receiver__contain = conditions.keyword;
    }
    if (conditions && conditions["statuses"]) {
      params.status__in = conditions.statuses;
    }
    const res = await callCollaborator("get", "mail_manager/mail_history")(params);
    const result = res.data.results[0];
    const mailHistories = result.result.map((m: MailHistory) => {
      return {
        id: m.id,
        receiver: m.receiver,
        subject: m.subject,
        status: Number(m.status),
        created_at: m.created_at,
      };
    });
    return mailHistories;
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    unselectMailSettings: (state) => {
      state.mailSettings = [];
    },
    unselectMailSetting: (state) => {
      state.selectedMailSetting = emptyMailSetting;
    },
    unselectMailHistories: (state) => {
      state.mailHistories = [];
    },
    clearWorkgroups: (state) => {
      state.workgroups = [];
    },
    removeWorkgroup: (state, action: PayloadAction<{ index: number }>) => {
      if (state.workgroups && 0 <= action.payload.index && action.payload.index < state.workgroups.length) {
        state.workgroups.splice(action.payload.index, 1);
      }
    },
    clearAccounts: (state) => {
      state.accounts = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMailSettings.fulfilled, (state, action) => {
      state.mailSettings = action.payload.mailSettings;
    });
    builder.addCase(getMailSetting.fulfilled, (state, action) => {
      state.selectedMailSetting = action.payload;
    });
    builder.addCase(updateMailSetting.fulfilled, (state, action) => {
      state.selectedMailSetting = action.payload;
    });
    builder.addCase(registerMailSetting.fulfilled, (state, action) => {
      state.selectedMailSetting = action.payload;
    });
    builder.addCase(getSendMailTask.fulfilled, (state, action) => {
      state.sendMailTask = action.payload;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(getWholeProjects.fulfilled, (state, action) => {
      state.wholeProjects = action.payload;
    });
    builder.addCase(getWorkgroups.fulfilled, (state, action) => {
      state.workgroups = action.payload;
      if (state.workgroups.some((w) => w?.length)) {
        state.projectLosted = false;
      }
    });
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload;
    });
    builder.addCase(getMailHistories.fulfilled, (state, action) => {
      state.mailHistories = action.payload;
    });
  },
});

export const { unselectMailSettings } = slice.actions;
export const { unselectMailSetting } = slice.actions;
export const { unselectMailHistories } = slice.actions;
export const { clearWorkgroups } = slice.actions;
export const { removeWorkgroup } = slice.actions;
export const { clearAccounts } = slice.actions;
export const selectMailSettingState = (state: RootState) => {
  return state.mailSetting as MailSettingState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
