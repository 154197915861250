import { useEffect, useMemo, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMailSettings, selectMailSettingState, unselectMailSettings, deleteMailSettings } from "./mailSettingSlice";
import { SEND_STATUS, SearchCondition } from "./mailSettingValues";
import { selectCurrentCompany } from "../login/userSlice";
import Table from "../../component/Table";
import { Row, Col, Button, Form, Accordion, Alert } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import ModalDialog from "../../component/ModalDialog";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const { mailSettings } = useAppSelector(selectMailSettingState);
  const current_company = useAppSelector(selectCurrentCompany);
  const [state, $state] = useState({
    timeoutId: null as number | null,
    inputKeyword: "", // 入力キーワード
    searchKeyword: "", // 検索用キーワード（入力し終わって500ms経過）
    statuses: [] as string[],
    checkedIds: [] as string[],
    activeModal: "",
  });

  useEffect(() => {
    if (current_company.id) {
      dispatch(getMailSettings({ conditions: {} }));
    }
  }, [current_company, dispatch]);

  useEffect(() => {
    updateMails();
  }, [state.searchKeyword]);

  useEffect(() => {
    return () => {
      dispatch(unselectMailSettings());
    };
  }, []);

  const userTimeZone = useMemo(() => {
    return current_company.timezone ?? "Asia/Tokyo";
  }, [current_company]);

  const updateMails = async () => {
    const conditions = {} as SearchCondition;
    if (state.searchKeyword) {
      conditions["keyword"] = state.searchKeyword;
    }
    dispatch(getMailSettings({ conditions }));
  };

  const discard = async () => {
    if (!state.checkedIds) return $state({ ...state, activeModal: "" });
    await dispatch(deleteMailSettings({ ids: state.checkedIds }));
    $state({ ...state, checkedIds: [], activeModal: "" });
    updateMails();
  };

  return (
    <>
      <Row>
        <Col>
          <Row className="mb-3">
            <Col md="2">
              <div className="--bold pt-md-3">絞込条件</div>
            </Col>
            <Col md="10">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {(() => {
                      const conditions = [];
                      if (state.searchKeyword) conditions.push("タイトル");
                      if (state.statuses.length) conditions.push("ステータス");
                      return conditions.length ? conditions.join(", ") : "設定なし";
                    })()}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form.Control
                      type="text"
                      id="search"
                      value={state.inputKeyword}
                      className="mb-3"
                      placeholder="タイトルで絞り込む"
                      onChange={(e) => {
                        const keyword = e.target.value;
                        // 打ち終わって500ms後に検索のリクエストをする
                        if (state.timeoutId) {
                          window.clearTimeout(state.timeoutId);
                        }
                        const timeoutId = window.setTimeout(() => {
                          $state({ ...state, timeoutId: null, searchKeyword: keyword, inputKeyword: keyword });
                        }, 500);
                        $state({ ...state, timeoutId, inputKeyword: keyword });
                      }}
                    />
                    <div className="--bold mb-1">ステータス</div>
                    {SEND_STATUS.map((s) => {
                      return (
                        <Form.Check
                          inline
                          type="checkbox"
                          key={`status_${s.value}`}
                          label={s.name}
                          id={`status_${s.value}`}
                          checked={state.statuses.includes(s.value)}
                          className="mx-2"
                          onChange={() => {
                            const next = state.statuses.some((_s) => _s === s.value)
                              ? state.statuses.filter((_s) => _s !== s.value)
                              : [...state.statuses, s.value];
                            $state({ ...state, statuses: next });
                          }}
                        />
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <div className="float-end">
                <Button
                  variant="outline-danger"
                  className="mx-2"
                  disabled={state.checkedIds.length === 0}
                  onClick={() => {
                    // 削除対象のうち1つでも 'waiting''ready' 以外であればエラーとする
                    if (
                      mailSettings.some(
                        (m) => state.checkedIds.includes(m.id) && m.status !== "waiting" && m.status !== "ready"
                      )
                    ) {
                      $state({ ...state, activeModal: "after_sending_time" });
                      return;
                    }
                    $state({ ...state, activeModal: "before_delete" });
                  }}
                >
                  選択したメール送信設定を削除する
                </Button>
              </div>
            </Col>
          </Row>
          {mailSettings.length > 0 ? (
            <>
              <Table
                col={[
                  {
                    name: "送信日時",
                    width: 300,
                  },
                  {
                    name: "タイトル",
                    width: 300,
                  },
                  {
                    name: "ステータス",
                    width: 250,
                  },
                  {
                    name: "詳細",
                    width: 100,
                  },
                  {
                    name: "送信履歴",
                    width: 100,
                  },
                ]}
                row={mailSettings.map((m) => {
                  return {
                    id: m.id,
                    key: m.id,
                    data: [
                      `${dayjs(m.eta).tz(userTimeZone).format("YYYY-MM-DD HH:mm")} (${userTimeZone})`,
                      m.name,
                      SEND_STATUS.find((s) => s.value === m.status)?.name,
                      <Link to={`/_/mail_setting/detail/${m.id}`}>確認する</Link>,
                      <Link to={`/_/mail_setting/history/${m.id}`}>確認する</Link>,
                    ],
                  };
                })}
                checkedIds={state.checkedIds}
                onCheck={(next) => {
                  const checkedIds = next as string[];
                  $state({ ...state, checkedIds });
                }}
              />
              {
                <Button variant="outline-secondary" className="mt-2 float-end" disabled={true}>
                  さらに表示（全 　 件中 　 件表示中）
                </Button>
              }
            </>
          ) : (
            <Alert variant={"info"}>該当するメール送信設定はありません。</Alert>
          )}
        </Col>
      </Row>
      <ModalDialog
        show={state.activeModal === "before_delete"}
        message="選択したメール送信設定を削除します。よろしいですか？"
        onConfirm={discard}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
      <ModalDialog
        show={state.activeModal === "after_sending_time"}
        message="送信日時を過ぎた設定は削除できません。"
        onConfirm={() => {
          $state({ ...state, activeModal: "" });
        }}
        type="alert"
      />
    </>
  );
}

export default App;
