import { useState, useEffect } from "react";
import { MultiSelect, Option } from "react-multi-select-component";
import { ChoiceItem } from "./profileValues";

function MultiSelectItem({
  choices,
  onChange,
  labelledBy,
}: {
  choices: ChoiceItem[];
  onChange: (selected: string[]) => any;
  labelledBy?: string;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = (open: boolean) => {
    setIsMenuOpen(open);
  };

  const onSearchItemChange = (selected: Option[]) => {
    onChange(selected.map((s) => s.value));
  };

  useEffect(() => {
    if (isMenuOpen) {
      const dropdownElement = document.querySelector(".dropdown-content");
      if (dropdownElement) {
        const rect = dropdownElement.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (rect.bottom > viewportHeight) {
          // メニューがウィンドウ外に出てしまう場合
          dropdownElement.setAttribute("style", "position: absolute; top: auto; bottom: 100%; z-index: 1000;");
        } else {
          // 通常の位置
          dropdownElement.setAttribute("style", "position: absolute; top: 100%; bottom: auto; z-index: 1000;");
        }
      }
    }
  }, [isMenuOpen]);

  return (
    <MultiSelect
      options={choices.map((c) => {
        const { value, label } = c;
        return { label: label || value, value };
      })}
      value={choices
        .filter((c) => c.checked)
        .map((c) => {
          const { value, label } = c;
          return { label: label || value, value };
        })}
      onChange={(selected: Option[]) => onSearchItemChange(selected)}
      onMenuToggle={handleMenuToggle} // メニュー開閉時にスタイル変更
      overrideStrings={{
        allItemsAreSelected: "全選択",
        clearSearch: "クリア",
        clearSelected: "クリア",
        noOptions: "選択肢なし",
        search: "検索",
        selectAll: "全選択",
        selectAllFiltered: "全選択(検索中)",
        selectSomeItems: "選択してください",
      }}
      labelledBy={labelledBy || "Select"}
    />
  );
}

export default MultiSelectItem;
