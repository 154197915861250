import { LangDictionary } from "../../app/translate";
const Dictionary = {
  // マイナンバーのステータス
  MY_NUMBER_STATUS_unregistered: { ja: "未登録", en: "unregistered" },
  MY_NUMBER_STATUS_todo: { ja: "登録中", en: "todo" },
  MY_NUMBER_STATUS_reviewing: { ja: "承認待ち", en: "reviewing" },
  MY_NUMBER_STATUS_rejected: { ja: "差し戻し中", en: "rejected" },
  MY_NUMBER_STATUS_done: { ja: "承認済", en: "done" },
  MY_NUMBER_STATUS_deletable: { ja: "削除予定", en: "deletable" },
  // マイナンバーの関係性
  MY_NUMBER_TYPE_self: { ja: "本人", en: "self" },
  MY_NUMBER_TYPE_spouse: { ja: "配偶者", en: "spouse" },
  MY_NUMBER_TYPE_dependent: { ja: "家族", en: "dependent" },
  // マイナンバーのファイルヘッダ
  MY_NUMBER_LOGIN_CODE: { ja: "ログインID", en: "login code" },
  MY_NUMBER_NAME: { ja: "氏名", en: "name" },
  MY_NUMBER_EMPLOYEE_NAME: { ja: "従業員氏名", en: "employee name" },
  MY_NUMBER_TYPE: { ja: "関係性", en: "relationship" },
  MY_NUMBER_DEPENDENT_SERIAL: { ja: "シリアル（家族）", en: "dependent serial" },
  MY_NUMBER_VALUE: { ja: "マイナンバー", en: "my number" },
  MY_NUMBER_STATUS: { ja: "ステータス", en: "status" },
} as LangDictionary;
export default Dictionary;
