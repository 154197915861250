import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import MyNumberRow from "./MyNumberRow";
import MyNumberLogsModal from "./MyNumberLogsModal";
import {
  getMyNumber,
  getMyNumberViews,
  selectMyNumberState,
  clearMyNumberValue,
  postMyNumber,
  getStatusMessages,
} from "./myNumberSlice";
import Sidebar from "../../component/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { MY_NUMBER_DISPLAY_MS, MyNumber } from "./myNumberValues";

function MyNumberAdminDetailView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountId } = useParams();
  const { myNumberViews, myNumberValue, statusMessages } = useAppSelector(selectMyNumberState);
  const [displayRequestedId, $displayRequestedId] = useState<string>("");
  const [isLogModalActive, $isLogModalActive] = useState(false);
  const [targetMyNumber, $targetMyNumber] = useState<MyNumber | undefined>(undefined);
  const [targetAccountName, $targetAccountName] = useState("");

  useEffect(() => {
    if (accountId) {
      dispatch(getMyNumberViews({ accountId: +accountId }));
    }
  }, [accountId]);

  const [self, spouse, dependents] = useMemo(() => {
    if (!accountId) return [undefined, undefined, []];
    const _myNumberViews = myNumberViews.filter((v) => v.account_id === +accountId);
    return [
      _myNumberViews.find((v) => v.type === "self"),
      _myNumberViews.find((v) => v.type === "spouse"),
      _myNumberViews.filter((v) => v.type === "dependent"),
    ];
  }, [myNumberViews, accountId]);

  useEffect(() => {
    // 差し戻し時のメッセージを取得
    const rejectedMyNumberIds = myNumberViews.filter((d) => d.status === "rejected").map((d) => d.id) as string[];
    if (rejectedMyNumberIds.length > 0) {
      dispatch(getStatusMessages({ myNumberIds: rejectedMyNumberIds }));
    }
  }, [myNumberViews]);

  useEffect(() => {
    // マイナンバーの値は、一定時間表示された後にクリアする
    if (myNumberValue !== "") setTimeout(() => dispatch(clearMyNumberValue()), MY_NUMBER_DISPLAY_MS);
  }, [myNumberValue]);

  const handler = ({ id: myNumberId, action, name }: { id: string; action: string; name: string }) => {
    if (action === "browse") {
      $displayRequestedId(myNumberId);
      dispatch(getMyNumber({ id: myNumberId }));
    } else if (action === "register") {
      const myNumberView = myNumberViews.find((v) => v.id === myNumberId);
      if (myNumberView?.id?.startsWith("_temp")) {
        dispatch(
          postMyNumber({
            accountId: myNumberView?.account_id ?? 0,
            type: myNumberView?.type ?? "",
            dependentSerial: myNumberView?.dependent_serial ?? undefined,
          })
        ).then((res: any) => {
          const id = res.payload.id;
          navigate(`/_/my_number/admin/${accountId}/edit/${id}`);
        });
        return;
      } else if (myNumberView?.id) {
        navigate(`/_/my_number/admin/${accountId}/edit/${myNumberView.id}`);
      }
    } else if (action === "review") {
      const matched = myNumberViews.find((v) => v.id === myNumberId);
      if (!matched) return;
      navigate(`/_/my_number/admin/${accountId}/edit/${matched.id}`);
    } else if (action === "list_logs") {
      const matched = myNumberViews.find((v) => v.id === myNumberId);
      if (!matched) return;
      $targetMyNumber(matched);
      $targetAccountName(name);
      $isLogModalActive(true);
    }
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      {self && (
        <div className="Layout__main">
          <h1 className="Headline--page">マイナンバー - {self.name}</h1>
          <main className="mt-3 py-4 px-md-2 bg-white">
            <Container>
              <Row>
                <Col>
                  <Alert variant="info">マイナンバーについての操作履歴は全て記録されます。</Alert>
                </Col>
              </Row>
              {self ? (
                <Row>
                  <Col>
                    <h2 className="Headline--section-sub">本人のマイナンバー</h2>
                    <MyNumberRow
                      id={self.id ?? ""}
                      value={self.id === displayRequestedId ? myNumberValue : ""}
                      name={self.name ?? ""}
                      status={self.status ?? ""}
                      status_reason={statusMessages[self.id ?? ""]}
                      canReview={true}
                      handler={handler}
                      isAdmin={true}
                    />
                  </Col>
                </Row>
              ) : null}
              <Row className="--align-items-center mt-4">
                <Col>
                  {spouse || dependents.length > 0 ? (
                    <>
                      <h2 className="Headline--section-sub">配偶者・家族のマイナンバー</h2>
                      <div>
                        {spouse && (
                          <MyNumberRow
                            id={spouse.id}
                            value={spouse.id === displayRequestedId ? myNumberValue : ""}
                            name={spouse.name}
                            status={spouse.status}
                            status_reason={statusMessages[spouse.id]}
                            canReview={true}
                            handler={handler}
                            className="mb-1"
                          />
                        )}
                        {dependents.length > 0 &&
                          dependents.map((d) => {
                            return (
                              <MyNumberRow
                                key={d.id}
                                id={d.id}
                                value={d.id === displayRequestedId ? myNumberValue : ""}
                                name={d.name}
                                status={d.status}
                                status_reason={statusMessages[d.id]}
                                canReview={true}
                                handler={handler}
                                className="mb-1"
                              />
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <span>配偶者・家族は登録されていません。</span>
                  )}
                </Col>
              </Row>
              <MyNumberLogsModal
                isActive={isLogModalActive}
                myNumber={targetMyNumber}
                name={targetAccountName}
                onClose={() => {
                  $isLogModalActive(false);
                  $targetAccountName("");
                }}
              />
            </Container>
          </main>
        </div>
      )}
    </div>
  );
}

export default MyNumberAdminDetailView;
