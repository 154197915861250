import { useEffect, useMemo, useState, Fragment } from "react";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { commitFileProject } from "./fileSlice";
import { selectUserState } from "../login/userSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import ModalDialog from "../../component/ModalDialog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FILE_PROJECT_DISPLAY_TYPES } from "./fileValues";
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector(selectUserState);

  const userTimeZone = useMemo(() => {
    return user.current_company.timezone;
  }, [user]);

  const [state, $state] = useState({
    name: "",
    description: "",
    startTime: new Date(),
    endTime: new Date(),
    displayType: "public",
    usePrefix: false,
    activeModal: "",
  });

  useEffect(() => {
    const oneHourAfter = dayjs().add(1, "hours").set("second", 0).set("millisecond", 0).tz(userTimeZone);
    const startTime = oneHourAfter.add(5 - (oneHourAfter.minute() % 5), "minutes");
    const endTime = dayjs(startTime).add(1, "years").tz(userTimeZone);
    $state({ ...state, startTime: startTime.toDate(), endTime: endTime.toDate() });
  }, [userTimeZone]);

  const errorMessages = useMemo(() => {
    const errorMessages = [] as { place: string; message: string }[];
    if (state.name === "") errorMessages.push({ place: "name", message: "入力してください" });
    if (state.startTime.getTime() >= state.endTime.getTime())
      errorMessages.push({ place: "endTime", message: "公開開始日時より後の日時を設定してください" });
    return errorMessages;
  }, [state]);

  const post = async () => {
    dispatch(
      commitFileProject({
        name: state.name,
        description: state.description,
        startTime: state.startTime.getTime(),
        endTime: state.endTime.getTime(),
        displayType: state.displayType,
        usePrefix: state.displayType === "private" && state.usePrefix,
      })
    ).then((res) => {
      $state({ ...state, activeModal: "" });
      const id = res.payload.id;
      navigate(`/_/files/file_admin/${id}`);
    });
  };

  return (
    <Container>
      <Alert variant="info">ファイル添付は保存後に実施できます</Alert>
      <Row>
        <Col>
          <div className="--required-label --bold">プロジェクト名</div>
          <Form.Control type="text" value={state.name} onChange={(e) => $state({ ...state, name: e.target.value })} />
          {(() => {
            const m = errorMessages?.find((message) => message.place === "name");
            return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
          })()}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="--bold">説明</div>
          <Form.Control
            as="textarea"
            value={state.description}
            onChange={(e) => $state({ ...state, description: e.target.value })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="--required-label --bold">公開開始日時</div>
          <DatePicker
            maxDate={state.endTime}
            dateFormat={"yyyy-MM-dd HH:mm"}
            selected={state.startTime}
            showTimeSelect
            className="form-control"
            timeIntervals={1}
            onChange={(selected) => {
              if (selected === null) return;
              $state({ ...state, startTime: selected });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="--required-label --bold">公開終了日時</div>
          <DatePicker
            minDate={state.startTime}
            dateFormat={"yyyy-MM-dd HH:mm"}
            selected={state.endTime}
            className="form-control"
            showTimeSelect
            timeIntervals={1}
            onChange={(selected) => {
              if (selected === null) return;
              $state({ ...state, endTime: selected });
            }}
          />
          {(() => {
            const m = errorMessages?.find((message) => message.place === "endTime");
            return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
          })()}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="--required-label --bold">公開タイプ</div>
          {FILE_PROJECT_DISPLAY_TYPES.map(({ value, label }, i) => (
            <Fragment key={`check_${i}`}>
              <Form.Check
                type="radio"
                label={label}
                key={`display_type_${value}`}
                id={`display_type_${value}`}
                checked={state.displayType === value}
                value={value}
                onChange={(e) => $state({ ...state, displayType: e.target.value })}
              />
              {value === "private" && state.displayType === "private" && (
                <div className="ms-4">
                  <Form.Check
                    type="checkbox"
                    label="アップロード時のファイル名の接頭辞とログインIDが一致する場合に権限を付与する"
                    key="option_usePrefix"
                    id="option_usePrefix"
                    checked={state.displayType === "private" && state.usePrefix}
                    onChange={() => $state({ ...state, usePrefix: !state.usePrefix })}
                  />
                  <div className="--font-s ms-4 text-muted">
                    ※チェックを入れると、アップロード時のファイル名先頭がログインIDと_（アンダースコア）である場合に権限が付与されます。
                  </div>
                  <div className="--font-s ms-4 text-muted">
                    （例）001_test.txt: ログインIDが001のアカウントに権限が付与されます。
                  </div>
                  <div className="--font-s ms-4 text-muted">※ゲストアカウント・無効アカウントは対象外です。</div>
                  <div className="--font-s ms-4 text-muted">※ファイル名の変更では権限が変更されません。</div>
                </div>
              )}
            </Fragment>
          ))}
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Button disabled={errorMessages.length > 0} onClick={() => $state({ ...state, activeModal: "before_save" })}>
            保存
          </Button>
        </Col>
      </Row>
      <ModalDialog
        show={state.activeModal === "before_save"}
        onConfirm={post}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message="保存します。よろしいですか？"
      />
    </Container>
  );
}

export default App;
