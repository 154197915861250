import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { call } from "../../app/api";
import { FileProject, GET_FILE_PROJECTS_LIMIT } from "./fileValues";
import { RootState } from "../../app/store";
import { DecodedFileData } from "../../component/Uploader";
import download from "../../app/download";

const SLICE_NAME = "file";

interface FileState {
  fileProjects: FileProject[];
  fileProjectFetchedPage: number;
  fileProjectHasMore: boolean;
  fileProjectTotalCount: number;
  selectedFileProject: FileProject;
}

const emptyFileProject: FileProject = {
  id: "",
  name: "",
  start_time: 0,
  end_time: 0,
  files: [],
  display_type: "public",
  use_prefix: false,
};

/*
  state の初期状態
*/
const initialState: FileState = {
  fileProjects: [],
  fileProjectFetchedPage: 0,
  fileProjectHasMore: false,
  fileProjectTotalCount: 0,
  selectedFileProject: emptyFileProject,
};

export const getFileProjectsAdmin = createAsyncThunk(
  SLICE_NAME + "/getFileProjectsAdmin",
  async ({ page = 1 }: { page?: number }) => {
    const res = await call(
      "get",
      "file_project_admin_manager/project"
    )({
      page,
      limit: GET_FILE_PROJECTS_LIMIT,
      sort_by: '[{ "created_at": -1 }]',
    });
    return {
      page,
      fileProjects: res.data.result,
      hasMore: res.data.has_more,
      count: res.data.count,
    };
  }
);

export const getFileProjects = createAsyncThunk(
  SLICE_NAME + "/getFileProjects",
  async ({ page = 1 }: { page?: number }) => {
    const res = await call(
      "get",
      "file_project_manager/project"
    )({
      // 一時的に全件取得の形とする（offset が指定できないため）
      // page,
      // limit: GET_FILE_PROJECTS_LIMIT,
      sort_by: '[{ "created_at": -1 }]',
    });
    return {
      page,
      fileProjects: res.data.result,
      hasMore: res.data.has_more,
      count: res.data.count,
    };
  }
);

export const getFileProject = createAsyncThunk(SLICE_NAME + "/getFileProject", async ({ id }: { id: string }) => {
  const res = await call("get", "file_project_admin_manager/project")({ id });
  return res.data.result[0];
});

export const commitFileProject = createAsyncThunk(
  SLICE_NAME + "/commitFileProject",
  async ({
    id,
    name,
    startTime,
    endTime,
    description,
    displayType,
    usePrefix,
  }: {
    id?: string;
    name: string;
    startTime: number;
    endTime: number;
    description: string;
    displayType: string;
    usePrefix: boolean;
  }) => {
    const res = await call(
      id ? "put" : "post",
      "file_project_admin_manager/project"
    )({
      id,
      name,
      start_time: startTime,
      end_time: endTime,
      description,
      display_type: displayType,
      use_prefix: usePrefix,
    });
    return res.data.result[0];
  }
);

export const deleteProject = createAsyncThunk(SLICE_NAME + "/deleteProject", async ({ id }: { id: string }) => {
  await call("delete", "file_project_admin_manager/project")({ id });
});

export const attachFile = createAsyncThunk(
  SLICE_NAME + "/attachFile",
  async ({ id, decodedFileData, isBulk }: { id: string; decodedFileData: DecodedFileData; isBulk: boolean }) => {
    const res = await call("post", "file_project_admin_manager/file", { handleError: false })({
      id,
      name: decodedFileData.name,
      file: decodedFileData.dataURI,
      is_bulk: isBulk,
    });

    return res.data.result[0];
  }
);

export const deleteFile = createAsyncThunk(
  SLICE_NAME + "/deleteFile",
  async ({ id, key }: { id: string; key: string }) => {
    const res = await call("delete", "file_project_admin_manager/file")({ id, key });
    return res.data.result[0];
  }
);

export const downloadFile = createAsyncThunk(
  SLICE_NAME + "/downloadFile",
  async ({ id, key, isAdmin }: { id: string; key?: string; isAdmin: boolean }) => {
    const api = isAdmin ? "file_project_admin_manager/file" : "file_project_manager/file";
    const res = await call("get", api)({ id, key });
    const { file, file_name, mime } = res.data.result;
    download({ file, fileName: file_name, mimeType: mime });
  }
);

export const putFileName = createAsyncThunk(
  SLICE_NAME + "/putFileName",
  async ({ id, key, name }: { id: string; key: string; name: string }) => {
    const res = await call("put", "file_project_admin_manager/file")({ id, files: [{ key, name }] });
    return res.data.result[0];
  }
);

export const putFileOrder = createAsyncThunk(
  SLICE_NAME + "/putFileOrder",
  async ({ id, keys }: { id: string; keys: string[] }) => {
    const res = await call("put", "file_project_admin_manager/file")({ id, files: keys.map((key) => ({ key })) });
    return res.data.result[0];
  }
);

export const downloadPermissions = createAsyncThunk(
  SLICE_NAME + "/downloadPermissions",
  async ({ id }: { id: string }) => {
    const res = await call("get", "file_project_admin_manager/permission_download")({ id });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    unselectFileProjects: (state) => {
      state.fileProjects = [];
      state.fileProjectFetchedPage = 0;
      state.fileProjectHasMore = false;
      state.fileProjectTotalCount = 0;
    },
    unselectFileProject: (state) => {
      state.selectedFileProject = emptyFileProject;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFileProjectsAdmin.fulfilled,
      (
        state,
        action: PayloadAction<{
          fileProjects: FileProject[];
          page: number;
          hasMore: boolean;
          count: number;
        }>
      ) => {
        state.fileProjects =
          action.payload.page === 1
            ? action.payload.fileProjects
            : [...state.fileProjects, ...action.payload.fileProjects];
        state.fileProjectHasMore = action.payload.hasMore;
        state.fileProjectTotalCount = action.payload.count;
        state.fileProjectFetchedPage = action.payload.page;
      }
    );
    builder.addCase(
      getFileProjects.fulfilled,
      (
        state,
        action: PayloadAction<{
          fileProjects: FileProject[];
          page: number;
          hasMore: boolean;
          count: number;
        }>
      ) => {
        state.fileProjects =
          action.payload.page === 1
            ? action.payload.fileProjects
            : [...state.fileProjects, ...action.payload.fileProjects];
        state.fileProjectHasMore = action.payload.hasMore;
        state.fileProjectTotalCount = action.payload.count;
        state.fileProjectFetchedPage = action.payload.page;
      }
    );

    builder.addCase(getFileProject.fulfilled, (state, action) => {
      state.selectedFileProject = action.payload;
    });

    builder.addCase(commitFileProject.fulfilled, (state, action) => {
      state.selectedFileProject = action.payload;
    });

    builder.addCase(attachFile.fulfilled, (state, action) => {
      state.selectedFileProject = action.payload;
    });

    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.selectedFileProject = action.payload;
    });

    builder.addCase(putFileName.fulfilled, (state, action) => {
      state.selectedFileProject = action.payload;
    });

    builder.addCase(putFileOrder.fulfilled, (state, action) => {
      state.selectedFileProject = action.payload;
    });
  },
});
export const { unselectFileProjects, unselectFileProject } = slice.actions;
export const selectFileState = (state: RootState) => {
  return state.file as FileState;
};
const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
