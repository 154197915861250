import { DecodedFileData } from "../../component/Uploader";

export const myNumberLogActionDictionary = {
  apply: "申請",
  approve: "承認",
  reject: "差し戻し",
  browse: "閲覧",
  register: "登録",
  set_deletable: "削除予定に設定",
  download: "ダウンロード",
  agree: "同意",
} as {
  [status: string]: string;
};

export type MyNumber = {
  id: string;
  account_id: number;
  type: "self" | "spouse" | "dependent";
  birthday: Date | null;
  dependent_serial: number | null;
  spouse_marriage_on: Date | null;
  value: string | null;
  status: string;
  number_file_id: string | null;
  identification_file_1_id: string | null;
  identification_file_2_id: string | null;
  applied_at: number | null;
  approved_at: number | null;
  registered_at: number | null;
  rejected_at: number | null;
  expired_at: number | null;
  agreed_at: number | null;
};

export type MyNumberLog = {
  id: string;
  objects: string[];
  subject: number;
  action_type: "apply" | "approved" | "reject" | "browse" | "set_deletable" | "download";
  time: number;
  remarks?: string;
};

export type MyNumberFiles = {
  [columnName: string]: DecodedFileData;
};

export type MyNumberView = MyNumber & {
  employee_name: string;
  enrollment_type: string;
  name: string;
};

export const MY_NUMBER_DISPLAY_MS = 5000;

export const MY_NUMBER_IMAGES = {
  number_file_id: {
    label: "番号確認資料",
    required: true,
  },
  identification_file_1_id: {
    label: "身分確認資料1",
    required: true,
  },
  identification_file_2_id: {
    label: "身分確認資料2",
    required: false,
    info: "顔写真のない証明書類の場合、身分確認資料が2点必要になります",
  },
} as {
  [key: string]: {
    label: string;
    required: boolean;
    info?: string;
  };
};

export type MyNumberFileColumn = "number_file_id" | "identification_file_1_id" | "identification_file_2_id";

export const MY_NUMBER_REVEIW_ACTIONS = [
  {
    label: "承認",
    value: "approve",
    className: "outline-success",
  },
  {
    label: "差し戻し",
    value: "reject",
    className: "outline-danger",
  },
] as {
  label: string;
  value: string;
  className: string;
}[];
